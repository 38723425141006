import React, { Fragment, useLayoutEffect } from "react";

import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { Lightbox } from "react-modal-image";

function LoaderProvider({ active, children }) {
  const displayState = useSelector((state) => state.display);
  const dispatch = useDispatch();

  const loading = displayState.loading;
  const lightbox = displayState.lightbox;
  useLayoutEffect(() => {
    if (loading.visible) {
      document.body.style.overflow = "hidden";
      document.body.style.height = "100%";
    } else {
      document.body.style.overflow = "auto";
      document.body.style.height = "auto";
    }
  }, [loading.visible]);

  const closeLightbox = () => {
    dispatch({ type: "HIDE_LIGHTBOX" });
  };
  return (
    <Fragment>
      {lightbox.visible && (
        <Lightbox
          medium={lightbox.smallSource}
          large={lightbox.largeSource}
          alt={lightbox.alt}
          hideZoom
          imageBackgroundColor="rgba(255,255,255,.9)"
          onClose={closeLightbox}
        />
      )}
      <LoadingOverlay
        className="loading-overlay"
        active={loading.visible}
        spinner
        text={loading.message}
      >
        {children}
      </LoadingOverlay>
    </Fragment>
  );
}

export default LoaderProvider;
