const model = {
  name: "agent",
  label: "Agen",
  add: true,
  edit: true,
  delete: true,
  view: true,
  list: true,
  listEndpoint: "agent",
  addEndpoint: "agent",
  deleteEndpoint: "agent/:id",
  viewEndpoint: "agent/:id",
  editEndpoint: "agent/:id",
  filter: "",
  masterdetail: false,
  field: [
    {
      name: "id",
      label: "ID",
      primary: true,
      type: "number",
      validate: "integer",
      number: true,
      list: false,
      view: true,
      add: false,
      edit: false,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: false,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },
    {
      name: "name",
      label: "Nama",
      primary: false,
      type: "text",
      validate: "string",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },
    {
      name: "name",
      label: "Nama",
      primary: false,
      type: "text",
      validate: "string",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },
    {
      name: "whatsapp_id",
      label: "Whatsapp",
      primary: false,
      type: "lookup",
      validate: "integer",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: true,
      relation_table: "whatsapp",
      relation_field: "id",
      relation_display: ["name", "phone"],
      lookupEndpoint: "whatsapp",
      relation_display_format: "%name% (%phone%)",
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },

    {
      name: "email",
      label: "Email",
      primary: false,
      type: "text",
      validate: "email",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: false,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },

    {
      name: "phone",
      label: "No WA",
      primary: false,
      type: "phone",
      validate: "phone",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },

    {
      name: "password",
      label: "Password",
      primary: false,
      type: "text",
      validate: "string",
      number: false,
      list: false,
      view: true,
      add: true,
      edit: false,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },
  ],
};

export default model;
