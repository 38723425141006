import React, { Fragment, memo } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
} from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";

function Permission({
  state,
  onChangeCheckbox,
  onSubmit,
  onBack,
  onSelectAll,
  onSearch,
}) {
  return (
    <Fragment>
      <Breadcrumb parent="Tables" title="Basic Tables" />
      <Container fluid={true} className="permission-page">
        <Row>
          <Col sm="12">
            <Card>
              {/* <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit();
                }}
              > */}
              <CardHeader className="d-flex flex-row justify-content-between pb-4 pt-4">
                <h5>Permission</h5>
              </CardHeader>

              <CardBody>
                <Form className="mb-3">
                  <FormGroup row>
                    <Col md={6}>
                      <Input
                        type="text"
                        name="text"
                        id="text"
                        placeholder="Cari ..."
                        onChange={(e) => onSearch(e.target.value)}
                      />
                    </Col>
                    {/* <Button color="primary" type="submit" className="ml-3">
                      Cari
                    </Button> */}
                  </FormGroup>
                </Form>
                <div className="table-responsive">
                  <Table className="permission-table">
                    <thead>
                      <tr>
                        <th rowSpan={2} className="align-middle">
                          Task
                        </th>
                        <th className="">Create</th>
                        <th className="">Read</th>
                        <th className="">Update</th>
                        <th className="">Delete</th>
                        <th className="">Lookup</th>
                      </tr>

                      <tr>
                        {/* <th></th> */}
                        {["create", "read", "update", "delete", "lookup"].map(
                          (selectAll, i) => (
                            <th key={`selectall-${i}`} className="text-center">
                              <Input
                                type="checkbox"
                                checked={
                                  state.permissions.filter(
                                    (v) => v[selectAll] === true
                                  ).length === state.permissions.length
                                }
                                onChange={(e) => {
                                  onSelectAll(
                                    selectAll,
                                    state.permissions.filter(
                                      (v) => v[selectAll] === true
                                    ).length === state.permissions.length
                                      ? false
                                      : true
                                  );
                                }}
                              />
                            </th>
                          )
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {state.permissions.map((permission, i) => {
                        if (!permission.task_name.includes(state.searchValue))
                          return null;
                        return (
                          <tr key={i}>
                            <td>{permission.task_name}</td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={permission.create}
                                onChange={(e) => {
                                  onChangeCheckbox(i, "create");
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={permission.read}
                                onChange={(e) => {
                                  onChangeCheckbox(i, "read");
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={permission.update}
                                onChange={(e) => {
                                  onChangeCheckbox(i, "update");
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={permission.delete}
                                onChange={(e) => {
                                  onChangeCheckbox(i, "delete");
                                }}
                              />
                            </td>
                            <td>
                              <Input
                                type="checkbox"
                                checked={permission.lookup}
                                onChange={(e) => {
                                  onChangeCheckbox(i, "lookup");
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <CardFooter className="d-flex flex-row justify-content-end">
                <div>
                  <Button
                    color="light"
                    size="md"
                    className="mr-1"
                    onClick={() => onBack()}
                  >
                    Batal
                  </Button>
                  <Button
                    color="primary"
                    size="md"
                    type="submit"
                    onClick={() => onSubmit()}
                  >
                    Simpan
                  </Button>
                </div>
              </CardFooter>
              {/* </Form> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default memo(Permission);
