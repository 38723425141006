import React from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { memo } from "react";
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Row,
  Col,
  Media,
} from "reactstrap";
import { Picker } from "emoji-mart";

function ChatInput({ onSend }) {
  const [input, setInput] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  let emojiRef = useRef(null);

  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, []);

  const handleClickOutside = (event) => {
    // console.log(emojiRef.current);
    // console.log("toggle emoji", event);
    if (
      emojiRef.current &&
      !emojiRef.current.contains(event.target) &&
      showEmojiPicker
    ) {
      console.log("emoji close");
      setShowEmojiPicker(false);
    }
  };

  const handleMessagePress = useCallback((event) => {
    onSend(event, input);
    setTimeout(() => {
      setInput("");
    }, 400);
  });

  const addEmoji = (emoji) => {
    const text = `${input}${emoji.native}`;
    setInput(text);
    setShowEmojiPicker(!showEmojiPicker);
  };

  const toggleEmojiPicker = () => {
    // if (showEmojiPicker) {
    //   document.addEventListener("click", handleClickOutside, true);
    // } else {
    //   document.removeEventListener("click", handleClickOutside, true);
    // }
    setShowEmojiPicker(!showEmojiPicker);
  };

  var totalChar = Math.floor(input.replace("\n", "").length / 75);
  var totalNL = input.split("\n").length > 0 ? input.split("\n").length : 1;

  // console.log(/\n/.exec(input));

  return (
    <div>
      <div className="emoji-mart-wrapper" ref={emojiRef}>
        {showEmojiPicker ? (
          <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
        ) : null}
      </div>

      <div className="chat-message-footer d-flex flex-row align-items-center">
        <Button className="emoji-box" onClick={toggleEmojiPicker}>
          <i className="ri-emotion-line"></i>
        </Button>
        <Input
          type="textarea"
          placeholder="Ketik pesan..."
          rows={totalChar + totalNL > 5 ? 5 : totalChar + totalNL}
          value={input}
          onKeyPress={(e) => {
            // console.log("E", e);
            if (
              e.key === "Enter" &&
              !e.shiftKey &&
              input.replace(/\s/g, "").length
            ) {
              handleMessagePress("send");
            }
          }}
          onChange={(e) => setInput(e.target.value)}
        />
        <Button
          className="send"
          onClick={() => handleMessagePress("send")}
          disabled={!input.replace(/\s/g, "").length}
        >
          <i className="ri-send-plane-fill"></i>
        </Button>
      </div>
    </div>
  );
  return (
    <div className="chat-message">
      <Row>
        <div className="mb-2">
          {showEmojiPicker ? (
            <Picker set="apple" emojiSize={30} onSelect={addEmoji} />
          ) : null}
        </div>
        <Col xl="12" className="d-flex">
          <div className="smiley-box bg-primary">
            <div className="picker" onClick={toggleEmojiPicker}>
              <Media
                src={require("../../../assets/images/smiley.png")}
                alt=""
              />
            </div>
          </div>
          <InputGroup className="text-box">
            <Input
              type="text"
              className="form-control input-txt-bx"
              placeholder="Type a message......"
              value={input}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleMessagePress("send");
                }
              }}
              onChange={(e) => setInput(e.target.value)}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="primary"
                onClick={() => handleMessagePress("send")}
              >
                Kirim
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
      </Row>
    </div>
  );
}

export default memo(ChatInput);
