import React, { useState, useRef } from "react";
import { useChatScroll, useDataLoader } from "use-chat-scroll";

function MessageList({ onInfinite, initialData }) {
  const [data, setData] = useState(initialData);
  const containerRef = useRef();
  const loader = useDataLoader(onInfinite, data, setData);
  useChatScroll(containerRef, data, loader);
  return <div></div>;

  //   return <div ref={containerRef} style={{height: "400px", width: "100%", overflow: 'auto'}}>
  //   {data.map(item => (
  //     // ...
  //   ))}
  // </div>
}

export default MessageList;
