function validate(data, validation) {
  let errors = {};
  for (let key of Object.keys(validation)) {
    var errorArr = [];
    var dataValue = data[key];
    var validateValue = validation[key];

    if (
      validateValue.required &&
      !dataValue &&
      validateValue.validate !== "array"
    )
      errorArr.push(
        `${validation[key].label ? validation[key].label : key} harus diisi`
      );

    if (validateValue.minLength && dataValue.length < 8) {
      errorArr.push(
        `${
          validation[key].label ? validation[key].label : key
        } tidak boleh kurang dari ${validateValue.minLength} karakter`
      );
    }
    if (
      validateValue.validate === "email" &&
      dataValue &&
      !validateEmail(dataValue)
    )
      errorArr.push("Email tidak valid");

    if (
      validateValue.validate === "phone" &&
      dataValue &&
      !validatePhone(dataValue)
    )
      errorArr.push("No HP tidak valid");

    if (
      validateValue.validate === "name" &&
      dataValue &&
      !validateName(dataValue)
    ) {
      errorArr.push("Masukkan nama yang benar");
    }

    if (
      validateValue.validate === "idcard" &&
      dataValue &&
      !validateIdCard(dataValue)
    ) {
      errorArr.push("Masukkan NIK yang benar");
    }

    if (
      validateValue.validate === "array" &&
      !Array.isArray(dataValue) &&
      !validateArray(dataValue)
    ) {
      errorArr.push(
        `${validation[key].label ? validation[key].label : key} harus diisi`
      );
    }

    if (errorArr.length > 0) errors[key] = errorArr.join(", ");
  }

  return errors;
}

function validateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    String(email).toLowerCase()
  );
}

function validatePhone(phone) {
  let isValid = /^\d*$/.test(phone) && phone.length > 6 && phone.length < 15;

  if (!isValid) return false;
  if (
    phone.charAt(0) == "6" ||
    phone.charAt(0) == "0" ||
    phone.charAt(0) == "+" ||
    phone.charAt(0) == "8"
  ) {
    return true;
  } else {
    return false;
  }
}

function validateName(name) {
  return /^[a-z A-Z]+$/.test(name);
}

function validateArray(array) {
  return Array.isArray(array) && array.length > 0;
}

function validateIdCard(number) {
  return /^\d*$/.test(number) && number.length === 16;
}

function validateOnce(key, validation, value) {
  return validate({ [key]: value }, { [key]: validation });
}

function formatPhone(phone) {
  var phoneNumber = phone;
  if (phone.charAt(0) == "0") {
    phoneNumber = "62" + phone.substring(1);
  } else if (phone.charAt(0) == "8") {
    phoneNumber = "62" + phone;
  } else {
  }

  return phoneNumber;
}

export {
  validate,
  validateEmail,
  validateName,
  validatePhone,
  validateIdCard,
  validateOnce,
  formatPhone,
};
