const INIT_STATE = {
  loading: {
    visible: false,
    message: "Mohon tunggu sebentar",
  },
  lightbox: {
    visible: false,
    mediumSource: "",
    largeSource: "",
    alt: "",
  },
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          visible:
            typeof action.payload.visible == "boolean"
              ? action.payload.visible
              : !state.loading.visible,
          message:
            typeof action.payload.message === "string"
              ? action.payload.message
              : state.loading.message,
        },
      };

    case "SHOW_LIGHTBOX":
      return {
        ...state,
        lightbox: {
          visible: true,
          mediumSource: action.payload.mediumSource,
          largeSource: action.payload.largeSource,
          alt: action.payload.alt,
        },
      };

    case "HIDE_LIGHTBOX":
      return {
        ...state,
        lightbox: {
          ...state.lightbox,
          visible: false,
        },
      };

    default:
      return { ...state };
  }
};
