import classNames from "classnames";
import React, { Fragment, memo, useState, useEffect } from "react";
import {
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import useLocalStorage from "../../hooks/useLocalStorage";
import { get, post } from "../../utils/API";
import { formatPhone } from "../../utils/Formatter";

function SwitchWhatsapp() {
  const [showModal, setShowModal] = useState(false);
  const [whatsapp, setWhatsapp] = useLocalStorage("whatsapp");
  const [waList, setWaList] = useState([]);

  const [selectedWa, setSelectedWa] = useState(whatsapp.whatsapp_id);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    get("whatsapp")
      .then((response) => {
        setWaList(response.data);
      })
      .catch((err) => {
        // Swal.fire("Gagal Switch WA", err.error_mesasge, "error");
      });

    if (!showModal) {
      setSelectedWa(whatsapp.whatsapp_id);
    }
  }, [showModal]);

  const onSwitch = () => {
    post("switch", { whatsapp_id: selectedWa }).then((response) => {
      //   window.location.reload();
      //   console.log("response", response);
      localStorage.setItem("whatsapp", JSON.stringify(response.whatsapp));
      localStorage.setItem("token", response.token);
      window.location.reload();
    });
  };
  return (
    <Fragment>
      <Modal
        size="md"
        isOpen={showModal}
        toggle={toggleModal}
        centered
        backdropClassName="modal-backdrop-default"
        className="switch-wa-modal"
      >
        <ModalHeader className="px-4" toggle={toggleModal}>
          Switch Whatsapp
        </ModalHeader>
        <ModalBody>
          <FormGroup tag="fieldset">
            {waList.map((d, i) => (
              <FormGroup
                check
                onClick={() => {
                  //   console.log(d.id);
                  setSelectedWa(d.id);
                }}
              >
                <Label
                  check={d.id == selectedWa}
                  className={classNames("form-checked-label", {
                    active: d.id == selectedWa,
                  })}
                >
                  <Input type="radio" name="radio-wa" />{" "}
                  {d.phone === d.name
                    ? formatPhone(d.name)
                    : `${formatPhone(d.name)} (${formatPhone(d.phone)})`}
                </Label>
              </FormGroup>
            ))}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="light" onClick={toggleModal}>
            Tutup
          </Button>
          <Button
            color="primary"
            onClick={onSwitch}
            disabled={selectedWa == whatsapp.whatsapp_id}
          >
            Ganti
          </Button>{" "}
        </ModalFooter>
      </Modal>

      <Button className="switch-whatsapp" onClick={() => setShowModal(true)}>
        <p className="mb-1">{formatPhone(whatsapp.name)}</p>
        <i className="ri-arrow-down-s-line"></i>
        {/* <p className="">{formatPhone(whatsapp.phone)}</p> */}
      </Button>
    </Fragment>
  );
}

export default memo(SwitchWhatsapp);
