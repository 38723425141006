import {
  Home,
  Airplay,
  Users,
  Settings,
  MessageSquare,
  Star,
  Tool,
  DollarSign,
  Database,
  Cast,
} from "react-feather";

export const MENUITEMS = [
  {
    menutitle: "General",
    menucontent: "Dashboards,Widgets",
    permission: "dashboard|report",
    Items: [
      {
        title: "Dashboard",
        icon: Home,
        type: "link",
        badge: "",
        badgetxt: "",
        permission: "dashboard",
        path: `${process.env.PUBLIC_URL}/dashboard/default`,
      },

      {
        title: "Report",
        icon: Settings,
        type: "link",

        path: `${process.env.PUBLIC_URL}/development`,
        permission: "report",
      },
    ],
  },

  {
    menutitle: "WA Blast & Kontak",
    menucontent: "Pesan, Broadcast, Kontak, dll",
    permission: "whatsapp|message|contacts|api-config",
    Items: [
      {
        title: "Device",
        icon: Cast,
        badge: "",
        badgetxt: "",
        type: "link",
        path: `${process.env.PUBLIC_URL}/blast/device/list`,
        permission: "whatsapp",
      },
      {
        title: "Broadcast",
        icon: Airplay,
        type: "link",
        badge: "",
        badgetxt: "",
        path: `${process.env.PUBLIC_URL}/blast/broadcast/list`,
        permission: "message",
      },
      {
        title: "Pesan",
        icon: MessageSquare,
        type: "link",
        badge: "",
        badgetxt: "",
        path: `${process.env.PUBLIC_URL}/blast/message/list`,
        permission: "message",
      },

      {
        title: "Secret Key",
        icon: Settings,
        type: "link",
        badge: "",
        badgetxt: "",
        permission: "api-config",
        path: `${process.env.PUBLIC_URL}/blast/config`,
      },
      {
        title: "Kontak",
        icon: Star,
        type: "link",
        badge: "",
        badgetxt: "",
        permission: "contacts",
        path: `${process.env.PUBLIC_URL}/blast/contact/list`,
      },

      {
        title: "Grup Kontak",
        icon: Users,
        type: "link",
        badge: "",
        badgetxt: "",
        path: `${process.env.PUBLIC_URL}/app/group-contact/list`,
        permission: "contacts-group",
      },
    ],
  },
  {
    menutitle: "Pengaturan",
    menucontent: "Agent, Users, Pengaturan, dll",
    permission: "agents|users",
    Items: [
      {
        title: "Agents",
        icon: Users,
        badge: "",
        badgetxt: "",
        type: "link",
        path: `${process.env.PUBLIC_URL}/app/agent/list`,
        permission: "agents",
      },
      {
        title: "Users",
        icon: Users,
        badge: "",
        badgetxt: "",
        type: "link",
        path: `${process.env.PUBLIC_URL}/app/users/list`,
        permission: "users",
      },
      {
        title: "Permission",
        icon: Tool,
        type: "sub",
        permission: 'roles|task',
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/roles/list`,
            title: "Roles",
            type: "link",
            permission: "roles",
          },
          {
            title: "Tasks",
            type: "link",
            path: `${process.env.PUBLIC_URL}/app/tasks/list`,

            permission: "task",
          },
        ],
      },

      // {
      //   title: "Data Master",
      //   icon: Database,
      //   type: "sub",
      //   children: [
      //     {
      //       path: `${process.env.PUBLIC_URL}/app/contact-category/list`,
      //       title: "Durasi Berlangganan",
      //       type: "link",
      //     },
      //   ],
      // },
    ],
  },
  {
    menutitle: "Menu Administrator",
    menucontent: "Subscribing, Users, Pengaturan",
    Items: [
      {
        title: "Subscribing",
        icon: DollarSign,
        type: "sub",
        permission: '',
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/duration/list`,
            title: "Durasi Berlangganan",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/subscription/list`,
            title: "Layanan Berlangganan",
            type: "link",
          },
          {
            path: `${process.env.PUBLIC_URL}/app/payment-methode/list`,
            title: "Metode Pembayaran",
            type: "link",
          },
        ],
      },
      {
        title: "Users",
        icon: Users,
        badge: "",
        badgetxt: "",
        type: "link",
        path: `${process.env.PUBLIC_URL}/app/users/list`,
      },
      {
        title: "Pengaturan",
        path: `${process.env.PUBLIC_URL}/development/admin`,
        icon: Tool,
        type: "link",
        children: [
          {
            path: `${process.env.PUBLIC_URL}/app/roles/list`,
            title: "Roles",
            type: "link",
          },
          {
            title: "Tasks",
            type: "link",
            path: `${process.env.PUBLIC_URL}/app/tasks/list`,
          },
        ],
      },
    ],
  },
];
