import React, { Component, Fragment } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  CardFooter,
  Table,
  Input,
  Label,
  Form,
  FormGroup,
  Badge,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import CountUp from "react-countup";
import { get } from "../../utils/API";
import socketIOClient from "socket.io-client";
import ConnectModal from "./components/ConnectModal";
import ConnectWhatsapp from "./components/ConnectWhatsapp";
import Swal from "sweetalert2";
import { formatPhone } from "../../utils/Formatter";

class DeviceListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      showQr: true,

      qrStatus: {
        loading: true,
        error: false,
      },
    };
  }

  componentDidMount() {
    this.getData();
    this.connectWA();
  }

  getData() {
    get("config").then((result) => {
      // Swal.fire("", JSON.stringify(result));
    });
    get("whatsapp")
      .then((result) => {
        this.setState({
          data: result.data,
        });
      })
      .catch((err) => {
        Swal.fire("Gagal", err.error_message, "error");
      });

    // setTimeout(() => {
    //   this.setState((prev) => ({
    //     qrStatus: {
    //       ...prev.qrStatus,
    //       loading: false,
    //     },
    //   }));
    // }, 5000);
  }

  connectWA(whatsappId) {}

  onQRScanned(data) {
    // this.setState({ loading: true });

    setTimeout(() => {
      // this.setState({ loading: false });
    }, 5000);
  }

  restartWA(whatsappId) {
    get(`whatsapp/restart/${whatsappId}`)
      .then(() => {
        Swal.fire("WA Berhasil direstart", "", "success");
      })
      .catch((err) => {
        Swal.fire("WA Gagal direstart", err.error_message, "error");
      });
  }

  render() {
    return (
      <Fragment>
        <Breadcrumbs parent="WA Blast" title="Device" />

        <Container fluid>
          <Button
            className="mb-4"
            color="primary"
            onClick={() => this.props.history.push(`/app/device/add/admin`)}
          >
            Tambah Device
          </Button>

          <Row>
            {this.state.data.map((data, i) => (
              <Col xl="4" md="3" sm="3" key={i}>
                <Card
                  className="custom-card widget-profile"
                  // onClick={() =>
                  //   this.props.history.push({
                  //     pathname: "/blast/chat/list/admin",
                  //     state: {
                  //       phone: data.phone,
                  //     },
                  //   })
                  // }
                >
                  <CardHeader>
                    <img
                      className="img-fluid"
                      src="https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
                      alt=""
                    />
                  </CardHeader>
                  <div className="card-profile p-relative">
                    <img
                      className="rounded-circle"
                      src={require("../../assets/images/logo/default-avatar.png")}
                      alt=""
                    />

                    {/* <div className="status-connected"> </div> */}
                  </div>

                  <div className="text-center profile-details mt-4 mb-4">
                    <h4>{formatPhone(data.name)}</h4>
                    {data.connect && (
                      // <Badge color={data.connect ? "success" : "light"}>
                      //   {data.connect && "Connected"}
                      //   {!data.connect && "Disconnect"}
                      // </Badge>
                      <div className="d-flex flex-row justify-content-center">
                        <Button
                          size="sm"
                          color="success"
                          // onClick={() => this.connectWA(data.id)}
                          onClick={() =>
                            this.props.history.push({
                              pathname: `/blast/chat/list/${data.id}/admin`,
                            })
                          }
                        >
                          Buka WA
                        </Button>

                        <Button
                          size="sm"
                          color="primary"
                          className="ml-2"
                          onClick={() => this.restartWA(data.id)}
                        >
                          Restart WA
                        </Button>
                      </div>
                    )}

                    {!data.connect && (
                      <Button
                        size="sm"
                        color="primary"
                        // onClick={() => this.connectWA(data.id)}
                        onClick={() =>
                          this.props.history.push({
                            pathname: `/blast/chat/list/${data.id}/admin`,
                          })
                        }
                      >
                        Hubungkan (Scan QR)
                      </Button>
                    )}
                  </div>
                  <CardFooter className="row">
                    <Col sm="4 col-4 d-flex flex-column justify-content-center">
                      <small className="text-dark font-bold flex-wrap">
                        Agent Aktif
                      </small>
                      <h3 className="counter">
                        <CountUp end={0} className="text-success" />
                      </h3>
                    </Col>
                    <Col sm="4 col-4">
                      <small className="text-dark font-bold">Pesan Baru</small>
                      <h3>
                        <span className="counter">
                          <CountUp end={0} className="text-warning" />
                        </span>
                      </h3>
                    </Col>
                    <Col sm="4 col-4">
                      <small className="text-dark font-bold text-truncate">
                        Sedang Ditangani
                      </small>
                      <h3>
                        <span className="counter">
                          <CountUp end={0} className="text-dark" />
                        </span>
                      </h3>
                    </Col>
                  </CardFooter>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default DeviceListController;
