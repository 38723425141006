import React, { useCallback } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { memo } from "react";
import { get, post } from "../../../utils/API";
import { useState } from "react";
import { toast } from "react-toastify";

function OpenSecretModal({ toggleModal, show, onSuccess, type = "show" }) {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const toggle = useCallback(() => toggleModal());

  const submit = () => {
    let endPoint = "config/secret-key/show";
    post(endPoint, { password: password })
      .then((response) => {
        if (type === "generate") {
          toast.success("Berhasil generate secret key");
        }
        onSuccess(response.secret_key);
        toggleModal();
      })
      .catch((err) => {
        setError(err.error_message);
      });
  };

  const generateSecret = () => {
    post("config/secret-key/generate", { password: password })
      .then((secret) => {
        submit();
      })
      .catch((err) => {
        setError(err.error_message);
      });
  };

  return (
    <div>
      <Modal isOpen={show} toggle={toggle} centered>
        <Form
          onSubmit={(e) => {
            e.preventDefault();

            if (type === "show") {
              submit();
            } else {
              generateSecret();
            }
          }}
        >
          <ModalHeader toggle={toggle}>
            {type === "show" ? "Tampilkan" : "Generate"} Secret Key
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="Masukkan password"
                value={password}
                onChange={(e) => {
                  setError("");
                  setPassword(e.target.value);
                }}
              />
              {error !== "" && <p className="text-danger">{error}</p>}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            {" "}
            <Button color="light" onClick={toggle}>
              Batal
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
}

export default memo(OpenSecretModal);
