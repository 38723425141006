import classNames from "classnames";
import React, { Fragment, memo } from "react";
import { useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Input,
} from "reactstrap";
import FormPagination from "../../../components/forms/FormPagination";
import Breadcrumb from "../../../layout/breadcrumb";
import { toIDR } from "../../../utils/Formatter";
import NotFound from "../../errors/NotFound";

function GenericList({
  state,
  onAdd,
  onView,
  onEdit,
  onDelete,
  onCustomAction,
  onChangePage,
  onSearch,
}) {
  const onChangePagination = useCallback((limit, offset) => {
    onChangePage(limit, offset);
  });

  return (
    <Fragment>
      <Breadcrumb parent="" title={`${state.model.label}`} />
      <Container fluid={true} className="generic-list-page">
        <Row>
          <Col sm="12">
            {state.notFound === true && <NotFound />}
            {state.notFound === false && (
              <Card>
                <CardHeader className="d-flex flex-row justify-content-between pb-4 pt-4">
                  <h5>{state.model.label}</h5>
                  {state.model.add && (
                    <Button size="md" color="primary" onClick={() => onAdd()}>
                      Tambah {state.model.label}
                    </Button>
                  )}
                </CardHeader>

                {typeof state.model.searchable === "boolean" &&
                  state.model.searchable === true && (
                    <div className="table-header row pt-3 pl-4">
                      <Col lg="6" className="d-flex flex-row">
                        <div className="p-relative input-wrapper pr-2">
                          <Input
                            id="search"
                            name="search"
                            placeholder="Cari..."
                            type="text"
                            onChange={(e) => onSearch(e.target.value)}
                            value={state.filter.search}
                          />

                          {typeof state.filter.search === "string" &&
                            state.filter.search.length > 0 && (
                              <Button
                                close
                                className="clear-button"
                                onClick={() => onSearch("")}
                              />
                            )}
                        </div>

                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => null}
                          className="ml-2 font-weight-bold"
                        >
                          Filter
                        </Button>
                      </Col>
                    </div>
                  )}

                <div className="table-filter row"></div>
                <div
                  className={classNames("table-responsive", {
                    loading: state.loading,
                  })}
                >
                  <Table className="generic-list">
                    <thead>
                      <tr>
                        <th>No</th>
                        {state.fields.map((field, i) => (
                          <th
                            key={`table-head-${i}`}
                            className={
                              field.wrapperClass &&
                              !field.wrapperClass.includes("d-flex")
                                ? field.wrapperClass
                                : ""
                            }
                            scope="col"
                          >
                            {field.label}
                          </th>
                        ))}
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.data.map((data, i) => (
                        <tr key={`table-row-${i}`}>
                          <td>{i + 1}</td>
                          {state.fields.map((field, i) => (
                            <td
                              key={`table-cell-${i}`}
                              style={{
                                verticalAlign: "middle",
                              }}
                            >
                              {!field.type && (
                                <div
                                  className={
                                    field.wrapperClass ? field.wrapperClass : ""
                                  }
                                >
                                  {field.display.map((dp, i) => (
                                    <p
                                      className={`mb-0 ${
                                        Array.isArray(field.class) &&
                                        Array.isArray(field.display) &&
                                        field.class.length ===
                                          field.display.length
                                          ? field.class[i]
                                          : ""
                                      }`}
                                    >
                                      {!["price"].includes(
                                        field.displayFormat
                                      ) && data[dp]}

                                      {field.displayFormat == "price" &&
                                        toIDR(data[dp])}
                                    </p>
                                  ))}
                                </div>
                              )}

                              {field.type === "switch" && (
                                <div
                                  className={
                                    field.wrapperClass ? field.wrapperClass : ""
                                  }
                                >
                                  {data[field.viewDisplay] == 1 && (
                                    <i className="ri-checkbox-circle-fill text-success h5"></i>
                                  )}

                                  {data[field.viewDisplay] == 0 && (
                                    <i className="ri-close-circle-fill text-secondary h5"></i>
                                  )}
                                </div>
                              )}

                              {field.type === "badge" && (
                                <div
                                  className={
                                    field.wrapperClass ? field.wrapperClass : ""
                                  }
                                >
                                  <Badge
                                    color={
                                      field.viewValues.find(
                                        (v) =>
                                          v.value == data[field.viewDisplay]
                                      )
                                        ? field.viewValues.find(
                                            (v) =>
                                              v.value == data[field.viewDisplay]
                                          ).color
                                        : "light"
                                    }
                                  >
                                    {field.viewValues.find(
                                      (v) => v.value == data[field.viewDisplay]
                                    )
                                      ? field.viewValues.find(
                                          (v) =>
                                            v.value == data[field.viewDisplay]
                                        ).label
                                      : "light"}
                                  </Badge>
                                </div>
                              )}
                              {field.type == "button" && (
                                <div
                                  className={
                                    field.wrapperClass ? field.wrapperClass : ""
                                  }
                                >
                                  {field.display.map((dp, i) => (
                                    <Button
                                      {...field.props}
                                      onClick={() => {
                                        // if ()
                                        return null;
                                      }}
                                    >
                                      {data[dp]}
                                    </Button>
                                  ))}
                                </div>
                              )}
                              {field.type == "image" && (
                                <div
                                  className={`avatar ${
                                    field.wrapperClass ? field.wrapperClass : ""
                                  }`}
                                >
                                  <img
                                    src={data[field.viewDisplay]}
                                    className="img-80 rounded-circle"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src = require("../../../assets/images/logo/default-avatar.png");
                                    }}
                                  />
                                </div>
                              )}
                            </td>
                          ))}

                          <td
                            className="p-relative"
                            style={{ minWidth: "100px" }}
                          >
                            <div className="action-button">
                              {state.model.view && (
                                <Button
                                  size="xs"
                                  color="light"
                                  className="mx-1"
                                  onClick={() => onView(data)}
                                >
                                  <i className="ri-information-line text-dark"></i>
                                </Button>
                              )}

                              {state.model.edit && (
                                <Button
                                  size="xs"
                                  color="light"
                                  className="mx-1"
                                  onClick={() => onEdit(data)}
                                >
                                  <i className="ri-edit-2-line text-primary"></i>
                                </Button>
                              )}

                              {state.model.delete && (
                                <Button
                                  size="xs"
                                  color="light"
                                  className="mx-1"
                                  onClick={() => onDelete(data)}
                                >
                                  <i className="ri-delete-bin-6-line text-danger"></i>
                                </Button>
                              )}
                              {Array.isArray(state.model.customActions) &&
                                state.model.customActions.map((action, i) => (
                                  <Button
                                    key={`action-${i}`}
                                    size="xs"
                                    color="light"
                                    className="mx-1"
                                    onClick={() => onCustomAction(data, action)}
                                  >
                                    <i className={action.icon}></i>
                                  </Button>
                                ))}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {state.totalRecord !== null && (
                  <FormPagination
                    totalRecord={state.totalRecord}
                    limit={state.filter.limit}
                    offset={state.filter.offset}
                    onChange={onChangePagination}
                  />
                )}
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default memo(GenericList);
