const model = {
  name: "roles",
  label: "Role",
  addEndpoint: "roles",
  listEndpoint: "roles",
  editEndpoint: "roles/:id",
  viewEndpoint: "roles/:id",
  deleteEndpoint: "roles/:id",
  add: true,
  edit: true,
  list: true,
  delete: true,
  customActions: [
    {
      label: "",
      icon: "ri-shield-star-fill text-success",
      iconColor: "warning",
      pathname: "/setting/permission/:id/admin",
    },
  ],
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "role_code",
      label: "Role Code",
      filter: false,
      list: {
        allow: true,
        display: ["role_code"],
        viewDisplay: "role_code",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["role_code"],
        displayFormat: "%0%",
        viewDisplay: "role_code",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["role_code"],
        displayFormat: "%0%",
        viewDisplay: "role_code",
      },
    },

    {
      name: "role_name",
      label: "Role Name",
      filter: false,
      list: {
        allow: true,
        display: ["role_name"],
        viewDisplay: "role_name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["role_name"],
        displayFormat: "%0%",
        viewDisplay: "role_name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["role_name"],
        displayFormat: "%0%",
        viewDisplay: "role_name",
      },
    },

    {
      name: "description",
      label: "Deskripsi",
      filter: false,
      list: {
        allow: true,
        display: ["description"],
        viewDisplay: "description",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
    },
  ],
};

export default model;
