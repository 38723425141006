import React, { Component, Fragment } from "react";
import { useChatScroll, useDataLoader } from "use-chat-scroll";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Form,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import four from "../../assets/images/user/4.jpg";
import one from "../../assets/images/user/1.jpg";
import two from "../../assets/images/user/2.png";
import errorImg from "../../assets/images/search-not-found.png";
import start_conversion from "../../assets/images/start-conversion.jpg";
import { Lightbox } from "react-modal-image";

import {
  CALL,
  STATUS,
  PROFILE,
  EricaHughes,
  VincentPorter,
  Active,
  ChataApp_p1,
  ChataApp_p2,
  Following,
  Follower,
  MarkJecno,
  Send,
} from "../../constant";
import { get, post } from "../../utils/API";
import {
  formatDate,
  formatPhone,
  formatChatDate,
  formatChat,
  formatChatList,
} from "../../utils/Formatter";
import classNames from "classnames";
import moment from "moment";
import socketIOClient from "socket.io-client";

import ChatInput from "./components/ChatInput";
import Socket from "../../services/Socket";
import ConnectWhatsapp from "../DeviceList/components/ConnectWhatsapp";
import ConnectWaiting from "./components/ConnectWaiting";
import Swal from "sweetalert2";
import RenderChat from "../../components/chats/RenderChat";
import MessageList from "./components/MessageList";

class ChatListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allMembers: null,
      members: null,
      chats: null,
      loading: false,
      currentUser: null,
      selectedUser: null,
      showEmojiPicker: false,
      messageInput: "",
      searchKeyword: "",
      menuToggle: false,
      activeTab: "1",
      isConnected: null,
      qrStatus: {
        loading: true,
        error: false,
        qrCode: "",
      },
      selectedChat: {
        messages: [],
      },

      deviceInfo: {},
    };

    this.messagesEnd = React.createRef(null);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.syncQR = this.syncQR.bind(this);
    this.chatContainerRef = React.createRef(null);
    this.onChatIncoming = this.onChatIncoming.bind(this);
  }

  componentDidMount() {
    this.getWA();

    // setTimeout(() => {
    //   this.setState(
    //     {
    //       qrStatus: {
    //         loading: true,
    //         error: false,
    //         qr: "",
    //       },
    //     },
    //     () => this.getData()
    //   );
    // }, 10000);
    Socket.on("qrcode", (data) => {
      // setResponse(data);
      // console.log("qrcode", data);

      console.log("receive qr", data.qr);
      if (this.state.qrStatus.qr !== data.qr) {
        this.setState((prev) => ({
          qrStatus: {
            ...prev.qrStatus,
            qrCode: data.qr,
            loading: false,
            error: false,
          },
        }));
      }
    });
    Socket.on("connect", () => console.log("connect success"));

    Socket.on("ready", (data) => {
      console.log("device ready");
      if (data.whatsapp_id != this.props.match.params.id) return false;

      this.setState(
        {
          qrStatus: {
            loading: true,
            error: false,
            qr: "",
          },
          isConnected: true,
        },
        () => this.getData()
      );
    });

    Socket.on("live-chat", (data) => {
      this.onChatIncoming(data);
      console.log("LIVE CHAT", data);
    });
  }

  onChatIncoming(data) {
    const messageType = {
      1: "TEXT",
      2: "IMAGE",
    };
    let _members = this.state.members;
    let _mIdx = _members.findIndex((v) => v.name == data.name);
    let memberModel = {};
    if (_mIdx > -1) {
      memberModel = {
        attachment: data.attachment,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        flag: data.flag,
        handle_by: null,
        handle_name: null,
        id: data.contact_id,
        isgroup: data.isgroup,
        message: data.text,
        message_type: messageType[data.type_id],
        type_id: data.type_id,
        name: data.name,
        photo: data.profilePic,
        status: data.status,
        whatsapp_id: data.whatsapp_id,
        whatsapp_name: data.whatsapp_number,
        whatsapp_number: data.whatsapp_number,
        whatsapp_photo: "",
        ..._members[_mIdx],
      };

      _members[_mIdx] = memberModel;
    } else {
      memberModel = {
        attachment: data.attachment,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        flag: data.flag,
        handle_by: null,
        handle_name: null,
        id: data.contact_id,
        isgroup: data.isgroup,
        message: data.text,
        message_type: messageType[data.type_id],
        type_id: data.type_id,
        name: data.name,
        photo: data.profilePic,
        status: data.status,
        whatsapp_id: data.whatsapp_id,
        whatsapp_name: data.whatsapp_number,
        whatsapp_number: data.whatsapp_number,
        whatsapp_photo: "",
      };

      _members.unshift(memberModel);
    }

    this.setState({ members: _members });
    if (data.contact_id == this.state.selectedChat.id) {
      let _selectedChat = this.state.selectedChat;
      _selectedChat.messages.push({
        attachment: data.attachment,
        date: moment().format("YYYY-MM-DD"),
        flag: data.flag,
        id: null,
        message: data.text,
        message_id: data.message_id,
        messge_type: messageType[data.type_id],
        status: data.status,
        time: moment().format("HH:mm:ss"),
        type_id: parseInt(data.type_id),
      });

      this.setState({ selectedChat: _selectedChat });
    }
  }

  componentWillUnmount() {
    Socket.off("qrcode");
    Socket.off("live-chat");
  }

  getWA() {
    get(`whatsapp/${this.props.match.params.id}`).then((result) => {
      this.setState(
        {
          isConnected: result.connect,
          deviceInfo: result,
        },
        () => {
          if (!result.connect) {
            this.syncQR();
          } else {
            this.getData();
          }
        }
      );
    });
  }
  syncQR() {
    const { id } = this.props.match.params;

    this.setState({
      qrStatus: {
        loading: true,
        error: false,
        qrCode: "",
      },
    });

    get(`whatsapp/connect/${id}`).then(() => {});
  }

  scrollToBottom() {
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  }

  async getData() {
    // allMembers && chats && selectedUser

    let members = await get(`messages`).then((result) => {
      return result.data;
    });

    // console.log("CHATS", members);

    if (members.length > 0) {
      let chats = await get(`live/chat/${members[0].id}`);

      await post("chat/handle", {
        contact_id: members[0].id,
      });

      this.setState(
        {
          chats: chats,
          allMembers: members,
          members: members,
          currentUser: JSON.parse(localStorage.getItem("profile")),
          selectedUser: members[0],
          isConnected: true,

          selectedChat: {
            ...members[0],
            messages: chats.data.reverse(),
          },
        },
        () => this.scrollToBottom()
      );
    }
  }

  toggleEmojiPicker() {
    this.setState((prev) => ({
      showEmojiPicker: !prev.showEmojiPicker,
    }));
  }

  addEmoji(emoji) {
    const text = `${this.state.messageInput}${emoji.native}`;

    this.setState({
      messageInput: text,
      showEmojiPicker: false,
    });
  }

  async changeChatClick(e, item) {
    await post(`chat/handle`, {
      contact_id: item.id,
    });

    await post("chat/handle/close", {
      contact_id: this.state.selectedChat.id,
    });

    get(`live/chat/${item.id}`).then((result) => {
      // let selectedUser = this.state.
      this.setState(
        (prev) => ({
          chats: result,
          selectedUser: item,
          selectedChat: {
            ...item,
            messages: result.data.reverse(),
          },
        }),
        () => {
          // this.scrollToBottom();
        }
      );
    });
  }

  handleSearchKeyword(keyword) {
    // this.setState({
    //   searchKeyword: keyword,
    // });
    // dispatch(searchMember(keyword));

    get(`messages`, { search: keyword }).then((response) => {
      console.log("response", response);
    });
  }

  setActiveTab(tab) {
    this.setState({ activeTab: tab });
  }

  handleMessageChange(message) {
    this.setState({
      messageInput: message,
    });
  }

  handleMessagePress(e) {
    console.log("EVENT", e);
    if (e.key === "Enter" || e === "send") {
      // let model = {
      //   attachment: "",
      //   date: moment().format("DD/MM/YYYY HH:mm"),
      //   flag: "O",
      //   id: null,
      //   message: this.state.messageInput,
      //   message_id: null,
      //   status: "Y",
      //   type: "chat",
      // };

      let model = {
        attachment: "",
        date: moment().format("YYYY-MM-DD"),
        flag: "O",
        id: null,
        message: this.state.messageInput,
        message_id: null,
        message_type: "TEXT",
        status: "Y",
        time: moment().format("HH:mm:ss"),
        type_id: 1,
      };

      // let _chats = this.state.chats;
      // _chats.unshift(model);
      const selectedChat = this.state.selectedChat;
      let _chats = this.state.selectedChat;
      _chats.messages.push(model);

      let _members = this.state.members;

      let idx = _members.findIndex((v) => v.id == this.state.selectedChat.id);

      _members[idx] = {
        attachment: "",
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        flag: "O",
        handle_by: null,
        handle_name: null,
        id: _chats.id,
        isgroup: false,
        message: this.state.messageInput,
        message_type: "TEXT",
        name: _chats.name,
        phone: _chats.phone,
        photo: _chats.photo,
        status: "Y",
        whatsapp_id: _chats.whatsapp_id,
        whatsapp_name: _chats.whatsapp_name,
        whatsapp_number: _chats.whatsapp_number,
        whatsapp_photo: _chats.whatsapp_photo,
      };

      //handled by live chat
      // this.setState({ chats: _chats, messageInput: "", members: _members });

      this.setState({ messageInput: "" });

      post("send-message", {
        contact_id: this.state.selectedChat.id,
        text: this.state.messageInput,
      })
        .then((response) => {
          // console.log(response);
          this.scrollToBottom();
        })
        .catch((err) => {
          Swal.fire("Gagal", err.error_message, "error");
        });
    }
  }

  chatMenuToggle() {
    this.setState((prev) => ({ menuToggle: !prev.menuToggle }));
  }

  render() {
    const {
      allMembers,
      members,
      chats,
      loading,
      currentUser,
      selectedUser,
      showEmojiPicker,
      messageInput,
      searchKeyword,
      activeTab,
      menuToggle,
      isConnected,
    } = this.state;

    const selectedChat = this.state.selectedChat;

    if (isConnected == null) {
      return <ConnectWaiting />;
    }

    if (isConnected == false) {
      return (
        <ConnectWhatsapp
          status={this.state.qrStatus}
          onReload={() => this.syncQR()}
        />
      );
    }

    var activeChat = 0;
    if (selectedUser != null) activeChat = selectedUser.id;
    var prevDate = null;

    return allMembers && chats && selectedUser ? (
      <Fragment>
        <Container fluid={true} className="live-chat">
          {isConnected == true && (
            <Row>
              <Col
                sm="12"
                md="4"
                className={classNames({
                  "call-chat-sidebar": false,
                  "chat-sidebar": true,
                })}
              >
                <Card>
                  <CardBody className="chat-body py-1">
                    <div className="chat-box">
                      <div className="chat-left-aside mt-4">
                        <div className="media px-4">
                          <Media
                            src={require("../../assets/images/logo/logo.png")}
                            className="rounded-circle user-image"
                            alt=""
                          />
                          <div className="about">
                            <div className="name f-w-600">
                              {this.state.deviceInfo.name}
                            </div>
                            <div className="status">
                              {formatPhone(this.state.deviceInfo.phone)}
                            </div>
                          </div>
                        </div>
                        <div className="people-list ">
                          <div className="search px-4">
                            <Form className="theme-form">
                              <FormGroup className="form-group p-relative">
                                <Input
                                  className="form-control"
                                  type="text"
                                  placeholder="Cari"
                                  defaultValue={searchKeyword}
                                  onChange={(e) => {
                                    // console.log("event", e);
                                    this.setState({
                                      searchKeyword: e.target.value,
                                    });
                                  }}
                                />
                                <i className="fa fa-search"></i>
                              </FormGroup>
                            </Form>
                          </div>

                          {members.length > 0 ? (
                            <ul className="list">
                              {members
                                .filter((v) =>
                                  v.name.includes(this.state.searchKeyword)
                                )
                                .map((item, i) => {
                                  return (
                                    <li
                                      className={`clearfix member-list ${
                                        selectedChat.id === item.id
                                          ? "active"
                                          : ""
                                      }`}
                                      key={`members-${i}`}
                                      onClick={(e) =>
                                        this.changeChatClick(e, item)
                                      }
                                    >
                                      <div className="p-relative">
                                        <img
                                          src={
                                            item.photo
                                              ? item.photo
                                              : require("../../assets/images/logo/logo.png")
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = require("../../assets/images/logo/logo.png");
                                          }}
                                          className="rounded-circle user-image"
                                          alt=""
                                        />
                                        <div
                                          className={`status-circle ${
                                            item.online === true
                                              ? "online"
                                              : "offline"
                                          }`}
                                        ></div>
                                        <div className="about">
                                          <div className="name text-truncate">
                                            {formatPhone(item.name)}
                                          </div>
                                          <p className="status text-truncate">
                                            {item.flag == "O" && (
                                              <i className="ri-check-double-line text-secondary h6 pt-1 align-middle"></i>
                                            )}
                                            {item.message &&
                                              item.message.substring(0, 12)}
                                            {item.flag == "I" &&
                                              item.message_type == "IMAGE" && (
                                                <Fragment>
                                                  <i className="ri-image-2-fill h6 align-middle"></i>
                                                  <span className="pl-1">
                                                    {item.message
                                                      ? item.message
                                                      : "Foto"}
                                                  </span>
                                                </Fragment>
                                              )}
                                          </p>
                                        </div>

                                        <div className="member-list-time">
                                          <span>
                                            {formatChatList(
                                              item.created_at,
                                              "YYYY-MM-DD HH:mm:ss"
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  );
                                })}
                            </ul>
                          ) : (
                            <Media
                              className="img-fluid m-auto"
                              src={errorImg}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col
                md={8}
                className={classNames({
                  "call-chat-body": false,
                })}
              >
                <Card className="overflow-hidden">
                  <CardBody className="p-0">
                    <div className="chat-box">
                      <div className="pr-0 chat-right-aside">
                        <div className="chat">
                          <div className="chat-header clearfix">
                            <Media
                              src={
                                selectedUser.photo
                                  ? selectedUser.photo
                                  : require("../../assets/images/logo/logo.png")
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require("../../assets/images/logo/logo.png");
                              }}
                              className="rounded-circle"
                              alt=""
                            />
                            <div className="about">
                              <div className="name">
                                {formatPhone(selectedChat.name)}
                              </div>
                              <div className="status digits">
                                {formatPhone(selectedChat.phone)}
                              </div>
                            </div>
                            <ul className="list-inline float-left float-sm-right chat-menu-icons"></ul>
                          </div>
                          <div className="chat-history chat-msg-box custom-scrollbar">
                            <ul>
                              {selectedChat.messages.length > 0 ? (
                                selectedChat.messages.map((item, index) => {
                                  const participators = allMembers.find(
                                    (x) => x.id === item.sender
                                  );

                                  return (
                                    <li key={index} className="clearfix">
                                      <RenderChat
                                        item={item}
                                        prevDate={
                                          index == 0
                                            ? null
                                            : selectedChat.messages[index - 1]
                                                .date
                                        }
                                      />
                                    </li>
                                  );
                                })
                              ) : (
                                <div>
                                  <Media
                                    className="img-fluid"
                                    src={start_conversion}
                                    alt="start conversion "
                                  />
                                </div>
                              )}

                              <div
                                style={{ float: "left", clear: "both" }}
                                ref={(el) => {
                                  this.messagesEnd = el;
                                }}
                              ></div>
                            </ul>
                          </div>

                          <ChatInput
                            onSend={(event, input) => {
                              this.setState({ messageInput: input }, () =>
                                this.handleMessagePress(event)
                              );
                            }}
                          />
                        </div>
                      </div>
                      <Col
                        className={`pl-0 chat-menu d-none ${
                          menuToggle ? "show" : ""
                        }`}
                      >
                        <Nav tabs className="nav  border-tab nav-primary">
                          <NavItem id="myTab" role="tablist">
                            <NavLink
                              tag="a"
                              href="#javascript"
                              className={activeTab === "1" ? "active" : ""}
                              onClick={() => this.setActiveTab("1")}
                            >
                              {CALL}
                            </NavLink>
                          </NavItem>
                          <NavItem id="myTab" role="tablist">
                            <NavLink
                              tag="a"
                              href="#javascript"
                              className={activeTab === "2" ? "active" : ""}
                              onClick={() => this.setActiveTab("2")}
                            >
                              {STATUS}
                            </NavLink>
                          </NavItem>
                          <NavItem id="myTab" role="tablist">
                            <NavLink
                              tag="a"
                              href="#javascript"
                              className={activeTab === "3" ? "active" : ""}
                              onClick={() => this.setActiveTab("3")}
                            >
                              {PROFILE}
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                          <TabPane tabId="1">
                            <div className="people-list">
                              <ul className="list digits custom-scrollbar">
                                <li className="clearfix">
                                  <Media
                                    className="rounded-circle user-image"
                                    src={four}
                                    alt=""
                                  />
                                  <div className="about">
                                    <div className="name">{EricaHughes}</div>
                                    <div className="status">
                                      <i className="fa fa-share font-success"></i>{" "}
                                      {"5 May, 4:40 PM"}
                                    </div>
                                  </div>
                                </li>
                                <li className="clearfix">
                                  <Media
                                    className="rounded-circle user-image mt-0"
                                    src={one}
                                    alt=""
                                  />
                                  <div className="about">
                                    <div className="name">
                                      {VincentPorter}
                                      <div className="status">
                                        <i className="fa fa-reply font-danger"></i>{" "}
                                        {"5 May, 5:30 PM"}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                {allMembers
                                  .filter((x) => x.id !== currentUser.id)
                                  .map((member, i) => (
                                    <li
                                      className="clearfix"
                                      key={i}
                                      onClick={(e) =>
                                        this.changeChatClick(e, member.id)
                                      }
                                    >
                                      <Media
                                        src={require("../../assets/images/logo/logo.png")}
                                        className="rounded-circle user-image"
                                        alt=""
                                      />
                                      <div className="about">
                                        <div className="name">
                                          {member.name}
                                        </div>
                                        <div className="status">
                                          {member.reply}
                                          {member.status}
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </TabPane>
                          <TabPane tabId="2">
                            <div className="people-list">
                              <div className="search">
                                <Form className="theme-form">
                                  <FormGroup>
                                    <Input
                                      className="form-control"
                                      type="text"
                                      placeholder="Write Status..."
                                    />
                                    <i className="fa fa-pencil"></i>
                                  </FormGroup>
                                </Form>
                              </div>
                            </div>
                            <div className="status">
                              <p className="font-dark">{Active}</p>
                              <hr />
                              <p>
                                {ChataApp_p1}
                                <i className="icofont icofont-emo-heart-eyes font-danger f-20"></i>
                                <i className="icofont icofont-emo-heart-eyes font-danger f-20 m-l-5"></i>
                              </p>
                              <hr />
                              <p>
                                {ChataApp_p2}
                                <i className="icofont icofont-emo-rolling-eyes font-success f-20"></i>
                              </p>
                            </div>
                          </TabPane>
                          <TabPane tabId="3">
                            <div className="user-profile">
                              <div className="image">
                                <div className="avatar text-center">
                                  <Media body alt="" src={two} />
                                </div>
                                <div className="icon-wrapper">
                                  <i className="icofont icofont-pencil-alt-5"></i>
                                </div>
                              </div>
                              <div className="user-content text-center">
                                <h5 className="text-uppercase">{MarkJecno}</h5>
                                <div className="social-media">
                                  <ul className="list-inline">
                                    <li className="list-inline-item">
                                      <a href="#javascript">
                                        <i className="fa fa-facebook"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="#javascript">
                                        <i className="fa fa-google-plus"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="#javascript">
                                        <i className="fa fa-twitter"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="#javascript">
                                        <i className="fa fa-instagram"></i>
                                      </a>
                                    </li>
                                    <li className="list-inline-item">
                                      <a href="#javascript">
                                        <i className="fa fa-rss"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <hr />
                                <div className="follow text-center">
                                  <Row>
                                    <Col className="border-right">
                                      <span>{Following}</span>
                                      <div className="follow-num">{"236k"}</div>
                                    </Col>
                                    <Col>
                                      <span>{Follower}</span>
                                      <div className="follow-num">
                                        {"3691k"}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                                <hr />
                                <div className="text-center digits">
                                  <p className="mb-0">
                                    {"Mark.jecno23@gmail.com"}
                                  </p>
                                  <p className="mb-0">
                                    {"+91 365 - 658 - 1236"}
                                  </p>
                                  <p className="mb-0">{"Fax: 123-4560"}</p>
                                </div>
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </Col>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </Fragment>
    ) : (
      <div className="loading"></div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default ChatListController;
