import React, { Component } from "react";
import Dashboard from "./Dashboard";
import { get } from "../../utils/API";

class DashboardController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waConnections: [],
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    Promise.all([get(`whatsapp`)]).then(([waList]) => {
      // console.log(waList);
      this.setState({
        waConnections: waList.data,
      });
    });
  }

  render() {
    return <Dashboard state={this.state} />;
  }
}

export default DashboardController;
