import React from "react";
import { memo, useState, useEffect } from "react";
import { Card, CardBody, Progress } from "reactstrap";

function ConnectWaiting() {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setProgress(20);
    }, 200);
    setTimeout(() => {
      setProgress(50);
    }, 400);

    setTimeout(() => {
      setProgress(90);
    }, 600);

    setTimeout(() => {
      setProgress(97);
    }, 1100);
  }, []);
  return (
    <Card className="connection-waiting">
      <CardBody>
        <div className="wrapper">
          <i className="ri-whatsapp-fill"></i>
          <Progress color="success" value={progress} />

          <h6 className="font-weight-bold text-center mt-1">WhatsApp</h6>
          <p className="text-muted text-center">
            Menghubungkan ke WhatsApp, mohon tunggu sebentar
          </p>
        </div>
      </CardBody>
    </Card>
  );
}

export default memo(ConnectWaiting);
