import React, { Component } from "react";
import { get } from "../../utils/API";
import BroadcastView from "./BroadcastView";

class BroadcastViewController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: {},
      data: {},
      isReady: false,
      refresh: false,
      totalRecord: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { id } = this.props.match.params;

    get(`broadcast/${id}`, this.state.filter)
      .then((response) => {
        this.setState({
          data: response,
        });
      })
      .catch((err) => {});
  }

  onBack() {
    this.props.history.goBack();
  }

  render() {
    return <BroadcastView state={this.state} onBack={this.onBack.bind(this)} />;
  }
}

export default BroadcastViewController;
