const model = {
  name: "contact",
  label: "Kontak",
  addEndpoint: "contacts",
  listEndpoint: "contacts",
  editEndpoint: "contacts/:id",
  viewEndpoint: "contacts/:id",
  list: false,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      list: { allow: false },
      add: { allow: false },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "photo",
      label: "Profil",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
        class: [],
        type: "image",
        wrapperClass: "d-flex flex-row justify-content-center",
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: false,
      },
      view: {
        allow: false,
      },
    },

    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name", "phone"],
        displayFormat: "%0% %1%",
        viewDisplay: "name",
        class: ["h6 font-weight-bold", ""],
        wrapperClass: "d-flex flex-column",
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
    },

    {
      name: "phone",
      label: "No HP",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },

      view: {
        allow: true,
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },

      delete: {
        allow: true,
      },
    },

    {
      name: "email",
      label: "Email",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "email",
        type: "email",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "email",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
      },

      view: {
        allow: true,
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
      },

      delete: {
        allow: true,
      },
    },

    {
      name: "address",
      label: "Alamat",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        display: ["address"],
        displayFormat: "%0%",
        viewDisplay: "address",
      },

      view: {
        allow: true,
        display: ["address"],
        displayFormat: "%0%",
        viewDisplay: "address",
      },

      delete: {
        allow: true,
      },
    },
  ],
};

export default model;
