import React, { Component } from "react";
import { toast } from "react-toastify";
import { get, edit } from "../../../utils/API";
import Permission from "./Permission";

class PermissionController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      permissions: [],
      name: "",
      searchValue: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { id } = this.props.match.params;
    get(`permissions/${id}`).then((response) => {
      this.setState({
        permissions: response.permissions,
        initialData: response.permissions,
        name: response.roleName,
      });
    });
  }

  onChangeCheckbox(index, action) {
    if (!["create", "read", "update", "delete", "lookup"].includes(action))
      return false;
    let permissions = this.state.permissions;
    permissions[index][action] = !permissions[index][action];
    this.setState({
      permissions: permissions,
    });
  }
  onBack() {
    this.props.history.goBack();
  }
  onSubmit() {
    let payload = {
      role_id: this.props.match.params.id,
      permissions: this.state.permissions,
    };
    edit("change-permissions", payload)
      .then((response) => {
        let _permissions = {};
        for (const permission of this.state.permissions) {
          _permissions[permission.task_name] = {
            read: permission.read,
            create: permission.create,
            update: permission.update,
            delete: permission.delete,
          };
        }

        // localStorage.setItem("permissions", JSON.stringify(_permissions));
        toast.success("Perubahan berhasil tersimpan");
        this.props.history.goBack();
      })
      .catch((err) => {
        toast.error(err.error_message || "Perubahan gagal tersimpan");
      });
  }

  onSelectAll(action, value) {
    let permissions = this.state.permissions;
    for (const permission of permissions) {
      if (permission.task_name.includes(this.state.searchValue)) {
        permission[action] = value;
      }
    }

    this.setState({ permissions: permissions });
  }

  onSearch(value) {
    this.setState({ searchValue: value });
  }

  render() {
    return (
      <Permission
        state={this.state}
        onChangeCheckbox={this.onChangeCheckbox.bind(this)}
        onBack={this.onBack.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        onSelectAll={this.onSelectAll.bind(this)}
        onSearch={this.onSearch.bind(this)}
      />
    );
  }
}

export default PermissionController;
