import React, { Fragment, memo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip,
  CardFooter,
} from "reactstrap";
import Breadcrumbs from "../../layout/breadcrumb";
import OpenSecretModal from "./components/OpenSecretModal";

function Config({ state, openSecretKey, setValue, webhook_url, onSubmit }) {
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [secretKey, setSecretKey] = useState(null);
  const [type, setType] = useState("show");
  const [tooltipText, setTooltipText] = useState("Salin ke Clipboard");
  const [tooltipCopy, setTooltipCopy] = useState(false);

  const togglePassword = (type = "show") => {
    setType(type === "generate" ? "generate" : "show");
    setShowPasswordModal(!showPasswordModal);
  };

  return (
    <Fragment>
      <OpenSecretModal
        type={type}
        toggleModal={togglePassword}
        show={showPasswordModal}
        onSuccess={(value) => {
          setSecretKey(value);
        }}
      />
      <Breadcrumbs parent="" title="" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Integrasi</h5>
              </CardHeader>
              <CardBody>
                <FormGroup row>
                  <Label for="secret_key" sm={2}>
                    Secret Key
                  </Label>
                  <Col
                    sm={10}
                    className="d-flex flex-row justify-content-start"
                  >
                    <Input
                      type="text"
                      name="secret_key"
                      id="secret_key"
                      disabled
                      value={secretKey ? secretKey : state.config.secret_key}
                    ></Input>
                    <Button
                      size="sm"
                      color="grey"
                      id="copy-secret"
                      disabled={!secretKey}
                      onClick={() => {
                        navigator.clipboard.writeText(secretKey);
                        setTooltipCopy(true);
                        setTooltipText("Berhasil");
                      }}
                    >
                      <i className="ri-file-copy-line h6"></i>
                    </Button>

                    <Button
                      className="ml-3"
                      color="primary"
                      disabled={secretKey}
                      onClick={togglePassword}
                    >
                      Tampilkan
                    </Button>
                  </Col>

                  <Col sm={{ size: 8, offset: 2 }}>
                    <Button
                      className="mt-2"
                      color="grey"
                      onClick={() => togglePassword("generate")}
                    >
                      Generate Secret Key
                    </Button>

                    <Button
                      className="ml-2 mt-2"
                      color="primary"
                      onClick={() =>
                        window
                          .open(
                            "https://documenter.getpostman.com/view/7637419/UVCBAjkj#f678b097-7c75-4e3a-beb1-bc51170ec3f0",
                            "_blank"
                          )
                          .focus()
                      }
                    >
                      <i className="ri-code-s-slash-line"></i>
                      {"  "}Dokumentasi API
                    </Button>
                  </Col>
                </FormGroup>

                <FormGroup row className="my-2">
                  <Label for="webhook" sm={2}>
                    Webhook
                  </Label>
                  <Col
                    sm={10}
                    className="d-flex flex-row justify-content-start"
                  >
                    <Input
                      type="text"
                      name="webhook"
                      id="webhook"
                      value={webhook_url}
                      onChange={(e) => setValue("webhook_url", e.target.value)}
                    />
                  </Col>
                </FormGroup>
              </CardBody>

              <CardFooter className="py-4">
                <FormGroup className="d-flex flex-row justify-content-end">
                  <Button
                    color="primary"
                    onClick={() => onSubmit()}
                    disabled={webhook_url === state.config.webhook_url}
                  >
                    Simpan
                  </Button>
                </FormGroup>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
      <Tooltip
        placement="top"
        isOpen={tooltipCopy}
        target="copy-secret"
        toggle={() => setTooltipCopy(!tooltipCopy)}
      >
        {tooltipText}
      </Tooltip>
    </Fragment>
  );
}

export default memo(Config);
