import React from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import { formatPhone } from "../../utils/Formatter";
import { Card, CardBody, Col, Row, Container, CardHeader } from "reactstrap";
import {
  smallchart1data,
  smallchart1option,
  smallchart2data,
  smallchart2option,
  smallchart3data,
  smallchart3option,
  smallchart4data,
  smallchart4option,
} from "../../components/dashboard/chartsData/chartist-charts-data";
import Knob from "knob";

import {
  //   Dashboard,
  Purchase,
  Sales,
  SalesReturn,
  PurchaseRet,
} from "../../constant";

import ChartistChart from "react-chartist";
import { useEffect } from "react";
import moment from "moment";

function Dashboard({ state }) {
  useEffect(() => {
    var ordervalue1 = Knob({
      value: 60,
      angleOffset: 0,
      thickness: 0.3,
      width: 65,
      fgColor: "#7366ff",
      readOnly: false,
      dynamicDraw: true,
      tickColorizeValues: true,
      bgColor: "#eef5fb",
      lineCap: "round",
      displayPrevious: false,
    });
    document.getElementById("ordervalue1").appendChild(ordervalue1);
  }, []);
  return (
    <div>
      <Breadcrumbs parent="Dashboard" title="Dashboard" />
      <Container fluid={true}>
        <Row>
          <Col xl={7}>
            <Row className="second-chart-list third-news-update">
              <Col xl="9 xl-100" className="chart_data_left box-col-12">
                <Card>
                  <CardBody className="p-0">
                    <Row className="m-0 chart-main">
                      <Col xl="4" md="6" sm="6" className="p-0 box-col-6">
                        <div className="media align-items-center">
                          <div className="hospital-small-chart">
                            <div className="small-bar">
                              <ChartistChart
                                className="small-chart flot-chart-container"
                                data={smallchart1data}
                                options={smallchart1option}
                                type={"Bar"}
                                listener={{
                                  draw: function (data) {
                                    if (data.type === "bar") {
                                      data.element.attr({
                                        style: "stroke-width: 3px",
                                      });
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="right-chart-content">
                              <h4>{"1001"}</h4>
                              <span>{Purchase} </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6" className="p-0 box-col-6">
                        <div className="media align-items-center">
                          <div className="hospital-small-chart">
                            <div className="small-bar">
                              <ChartistChart
                                className="small-chart1 flot-chart-container"
                                data={smallchart2data}
                                options={smallchart2option}
                                type={"Bar"}
                                listener={{
                                  draw: function (data) {
                                    if (data.type === "bar") {
                                      data.element.attr({
                                        style: "stroke-width: 3px",
                                      });
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="right-chart-content">
                              <h4>{"1005"}</h4>
                              <span>{Sales}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col xl="4" md="6" sm="6" className="p-0 box-col-6">
                        <div className="media align-items-center">
                          <div className="hospital-small-chart">
                            <div className="small-bar">
                              <ChartistChart
                                className="small-chart2 flot-chart-container"
                                data={smallchart3data}
                                options={smallchart3option}
                                type={"Bar"}
                                listener={{
                                  draw: function (data) {
                                    if (data.type === "bar") {
                                      data.element.attr({
                                        style: "stroke-width: 3px",
                                      });
                                    }
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="right-chart-content">
                              <h4>{"100"}</h4>
                              <span>{SalesReturn}</span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col xl={5}>
                <Card>
                  <CardBody>
                    <div className="media align-items-center">
                      <div className="media-body right-chart-content">
                        <h4>
                          {"$95,900"}
                          <span className="new-box">New</span>
                        </h4>
                        <span>Purchase order Value</span>
                      </div>
                      <div className="knob-block text-center">
                        <div className="knob1" id="ordervalue1"></div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={7}>
                <Card className="bg-img">
                  <CardHeader>
                    <div className="header-top">
                      <h5 className="m-0">{"Alert"} </h5>
                      <div className="dot-right-icon">
                        <i className="fa fa-ellipsis-h"></i>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="body-bottom">
                      <h6> {"10% off For drama lights Couslations..."}</h6>
                      <span className="font-roboto">
                        {
                          "Lorem Ipsum is simply dummy...It is a long established fact that a reader will be distracted by "
                        }{" "}
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xl={5}>
            <Card>
              <CardHeader className="card-no-border">
                <div className="header-top">
                  <h5 className="m-0">Status Koneksi</h5>
                  <div className="card-header-right-icon">
                    <select className="button btn btn-primary">
                      <option>Hari Ini</option>
                      <option>Besok</option>
                      <option>Kemarin</option>
                    </select>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <div className="appointment-table table-responsive">
                  <table className="table table-bordernone">
                    <tbody>
                      {state.waConnections.map((wa, i) => (
                        <tr key={`wa-${i}`}>
                          <td>
                            <img
                              className="img-fluid img-40 rounded-circle mb-3"
                              src={wa.photo}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require("../../assets/images/logo/default-avatar.png");
                              }}
                              alt=""
                            />
                            <div className="status-circle bg-primary"></div>
                          </td>
                          <td className="img-content-box">
                            <span className="d-block">
                              {formatPhone(wa.name)}
                            </span>
                            <span className="font-roboto">
                              {moment(wa.expire_date).format("D MMM")}
                            </span>
                          </td>
                          {/* <td>
                            <p className="m-0 font-primary">{"28 Sept"}</p>
                          </td>
                          <td className="text-right">
                            <div className="button btn btn-primary">
                              Done
                              <i className="fa fa-check-circle ml-2"></i>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
