import React, { Fragment } from "react";
import { memo, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Swal from "sweetalert2";
import { post } from "../utils/API";
import { toast } from "react-toastify";
import { useCallback } from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

function Signup({ onBack }) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showRetypePassword, setShowRetypePassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const onPressBack = useCallback(() => {
    onBack();
  });

  const history = useHistory();

  const onRegister = () => {
    let payload = {
      name,
      phone,
      email,
      password,
      retype_password: retypePassword,
    };

    if (password !== retypePassword) {
      Swal.fire({
        title: "Pendaftaran gagal",
        text: "Password tidak sama",
        icon: "error",
      });
      return false;
    }

    setLoading(true);

    post("register", payload)
      .then((data) => {
        setLoading(false);
        toast.success("Pendaftaran berhasil");

        localStorage.setItem("profile", JSON.stringify(data.profile));

        localStorage.setItem("token", data.token);
        // setValue(data.profile.id);
        // setName(data.profile.name);

        localStorage.setItem("Name", data.profile.name);
        localStorage.setItem("layout", "admin");

        setTimeout(() => {
          history.push("/blast/device/list/admin", { replace: true });
        }, 400);
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: "Pendaftaran gagal",
          text: err.error_message,
          icon: "error",
        });
      });
  };

  return (
    <Fragment>
      <h4 className="font-weight-bold">Daftar</h4>
      <p className="text-muted mb-5">Isi form untuk melanjukan</p>
      {/* <Form
        onSubmit={(e) => {
          e.preventDefault();
          onRegister();
        }}
      > */}
      <FormGroup>
        <Label for="name">Nama</Label>
        <Input
          type="text"
          name="name"
          id="name"
          placeholder="John Doe"
          onChange={(e) => setName(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="phone">Phone</Label>
        <Input
          type="text"
          name="phone"
          id="phone"
          placeholder="085100000000"
          onChange={(e) => setPhone(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="email">Email</Label>
        <Input
          type="text"
          name="email"
          id="email"
          placeholder="wa@gmail.com"
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password</Label>
        <InputGroup className="input-password">
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder=""
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <InputGroupAddon
            addonType="append"
            onClick={() => setShowPassword(!showPassword)}
          >
            <i
              className={classNames({
                "ri-eye-line": showPassword,
                "ri-eye-off-line": !showPassword,
                "text-dark": !showPassword,
              })}
            ></i>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label for="retype_password">Konfirmasi Password</Label>

        <InputGroup className="input-password">
          <Input
            type={showRetypePassword ? "text" : "password"}
            name="retype_password"
            id="retype_password"
            placeholder=""
            onChange={(e) => setRetypePassword(e.target.value)}
            required
          />

          <InputGroupAddon
            addonType="append"
            onClick={() => setShowRetypePassword(!showRetypePassword)}
          >
            <i
              className={classNames({
                "ri-eye-line": showRetypePassword,
                "ri-eye-off-line": !showRetypePassword,
                "text-dark": !showRetypePassword,
              })}
            ></i>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>

      <FormGroup className="d-flex flex-row justify-content-center">
        <Button
          disabled={loading}
          size="sm"
          color="primary"
          type="submit"
          style={{
            width: "60%",
          }}
          onClick={onRegister}
        >
          Daftar
        </Button>
      </FormGroup>
      {/* </Form> */}

      <div className="d-flex flex-row align-items-center">
        <Button
          size="xs"
          link
          className="text-primary"
          color="white"
          style={{
            border: "none",
          }}
          onClick={onPressBack}
        >
          &lt; Kembali
        </Button>
      </div>
    </Fragment>
  );
}

export default memo(Signup);
