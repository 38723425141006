import React, { Component } from "react";
import Config from "./Config";
import { get, post } from "../../utils/API";
import { toast } from "react-toastify";

class ConfigController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {},
      secretKey: "",
      webhook_url: "",
    };
  }
  componentDidMount() {
    this.getData();
    this.openSecretKey();
  }

  getData() {
    Promise.all([get("/config/secret-key ")]).then(([config]) => {
      this.setState({ config: config, webhook_url: config.webhook_url });
    });
  }

  openSecretKey(password) {
    post("config/secret-key/show", { password: "semarang" }).then(
      (response) => {
        this.setState({ secretKey: response.secret_key });
      }
    );
  }

  setValue(key, value) {
    this.setState({ [key]: value });
  }

  onSubmit() {
    let payload = {
      webhook: true,
      webhook_url: this.state.webhook_url,
    };

    if (this.state.webhook_url === "") {
      payload.webhook = false;
    }
    post("config/webhook", payload)
      .then((response) => {
        toast.success("Pengaturan berhasil disimpan");
      })
      .catch((err) => {
        toast.error(err.error_message);
      });
  }

  render() {
    return (
      <Config
        state={this.state}
        setValue={this.setValue.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
      />
    );
  }
}

export default ConfigController;
