const model = {
  name: "payment-methode",
  label: "Metode Pembayaran",
  addEndpoint: "payment_methode",
  listEndpoint: "payment_methode",
  editEndpoint: "payment_methode",
  viewEndpoint: "payment_methode/:id",
  deleteEndpoint: "payment_methode/:id",
  add: true,
  edit: true,
  list: true,
  delete: true,
  view: true,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        viewDisplay: "name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "radio",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "BRI Virtual Account",
            value: "BRIVA",
          },

          {
            label: "BNI Virtual Account",
            value: "BNIVA",
          },

          {
            label: "Mandiri Virtual Account",
            value: "MANDIRIVA",
          },
        ],
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
    },

    {
      name: "code",
      label: "Kode",
      filter: false,
      list: {
        allow: true,
        display: ["code"],
        viewDisplay: "code",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
    },

    {
      name: "account_number",
      label: "Nomor Rekening",
      filter: false,
      list: {
        allow: true,
        display: ["account_number"],
        viewDisplay: "account_number",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        display: ["account_number"],
        displayFormat: "%0%",
        viewDisplay: "account_number",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["account_number"],
        displayFormat: "%0%",
        viewDisplay: "account_number",
      },
    },

    {
      name: "account_name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["account_name"],
        viewDisplay: "account_name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        display: ["account_name"],
        displayFormat: "%0%",
        viewDisplay: "account_name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["account_number"],
        displayFormat: "%0%",
        viewDisplay: "account_number",
      },
    },
    {
      name: "payment_type",
      label: "Tipe Pembayaran",
      filter: false,
      list: {
        allow: true,
        display: ["payment_type"],
        viewDisplay: "payment_type",
        displayFormat: "%0%",
        image: true,
        wrapperClass: "text-center",
      },
      add: {
        allow: true,
        validate: "string",
        type: "select",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "Virtual Akun",
            value: "VA",
          },
          {
            label: "Transfer Bank",
            value: "TFBANK",
          },
          {
            label: "QRIS",
            value: "QRIS",
          },
          {
            label: "STORE",
            value: "STORE",
          },
        ],
      },
      edit: {
        allow: true,
        validate: "string",
        type: "select",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "Virtual Akun",
            value: "VA",
          },
          {
            label: "Transfer Bank",
            value: "TFBANK",
          },
          {
            label: "QRIS",
            value: "QRIS",
          },
          {
            label: "STORE",
            value: "STORE",
          },
        ],
        display: ["payment_type"],
        displayFormat: "%0%",
        viewDisplay: "payment_type",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["payment_type"],
        displayFormat: "%0%",
        viewDisplay: "payment_type",
      },
    },
    {
      name: "active",
      label: "Status",
      filter: false,
      list: {
        allow: true,
        display: ["active"],
        viewDisplay: "active",
        displayFormat: "%0%",
        image: true,
        type: "badge",
        wrapperClass: "text-center",
        viewValues: [
          {
            label: "Aktif",
            value: 1,
            color: "success",
          },
          {
            label: "Tidak Aktif",
            value: 0,

            color: "secondary",
          },
        ],
      },
      add: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],

        default: "1",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
    },
  ],
};

export default model;
