import axios from "axios";

const API = "https://api.chatku.xyz";

export const fetchProductApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/product.json`);
};

export const fetchChatApi1 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chatMember.json`);
};

export const fetchChatApi3 = () => {
  return axios.get(`${API}/list/chat/089525690990`, {
    headers: {
      Authorization:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJhcGlfdG9rZW4iOiI0ZWJhMmU4ODkzNjhhZTExOThhYWFkNDgxZjE0OWQyYzc1Yzg2ZjM1Iiwicm9sZV9pZCI6LTEsImlhdCI6MTY0MDU5NzA4OH0.YeSCq96WFJvsxY82lTxbe681o6ux7ZdF8nEgvrhYRwU",
    },
  });
};

export const fetchChatApi2 = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/chat.chats.json`);
};

export const fetchEmailApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/email.json`);
};

export const fetchBookmaekApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/bookmark.json`);
};

export const fetchTodoApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/todo.json`);
};

export const fetchTaskApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/task.json`);
};

export const fetchProjectApi = () => {
  return axios.get(`${process.env.PUBLIC_URL}/api/project.json`);
};
