import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
function FormPagination({ totalRecord, limit, offset, onChange }) {
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);

  const [prevPages, setPrevPages] = useState([]);
  const [nextPages, setNextPages] = useState([]);

  useEffect(() => {
    let totalPage = Math.floor(totalRecord / limit);
    let _currentPage = Math.floor(offset / limit) + 1;
    let _pages = [];

    if (totalPage == 0) {
      setPages([1]);
      setLastPage(1);
      setCurrentPage(1);
      return false;
    }
    if (_currentPage == 1) {
      _pages = [1, 2, 3];
    } else {
      _pages = [_currentPage - 1, _currentPage, _currentPage + 1];
    }

    if (_pages[0] !== 1) {
      _pages.unshift(1);
    }

    setPages(_pages);
    setLastPage(totalPage);
    setCurrentPage(_currentPage);
  }, [limit, offset]);
  return (
    <Row>
      <Col md={12} className="d-flex flex-row justify-content-end p-5">
        <Form inline onSubmit={(e) => e.preventDefault()}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="perpage" className="mr-sm-2">
              Per page
            </Label>
            <Input
              type="select"
              name="perpage"
              id="perpage"
              value={limit}
              onChange={(e) =>
                onChange instanceof Function
                  ? onChange(parseInt(e.target.value), 0)
                  : null
              }
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </Input>
          </FormGroup>
        </Form>

        <Pagination aria-label="Page navigation example">
          <PaginationItem
            disabled={currentPage == 1}
            onClick={() => onChange(limit, offset - limit)}
          >
            <PaginationLink previous />
          </PaginationItem>
          {Array.isArray(pages) &&
            pages.map((page, i) => {
              if (page >= lastPage) {
                return null;
              }
              return (
                <PaginationItem
                  onClick={() => onChange(limit, (page - 1) * limit)}
                  key={`pagination-${i}`}
                  disabled={page == currentPage}
                >
                  <PaginationLink>{page}</PaginationLink>
                </PaginationItem>
              );
            })}

          {pages.length > 2 && (
            <PaginationItem disabled>
              <PaginationLink>...</PaginationLink>
            </PaginationItem>
          )}
          <PaginationItem
            disabled={currentPage == lastPage}
            onClick={() => onChange(limit, lastPage * limit)}
          >
            <PaginationLink>{lastPage}</PaginationLink>
          </PaginationItem>

          <PaginationItem
            disabled={currentPage == lastPage}
            onClick={() => onChange(limit, offset + limit)}
          >
            <PaginationLink next />
          </PaginationItem>
        </Pagination>
      </Col>
    </Row>
  );
}

export default FormPagination;
