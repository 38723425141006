import classNames from "classnames";
import React from "react";
import { memo } from "react";
import { useCallback, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

function InputPassword({ field, onChangeInput, error }) {
  const [showPassword, setShowPassword] = useState(false);

  const onChange = useCallback((event) => {
    onChangeInput(event.target.name, event.target.value);
  });
  return (
    <FormGroup>
      <Label
        className={classNames({
          required: field.required,
        })}
        for={field.name}
      >
        {field.label}
      </Label>

      <InputGroup className="input-password">
        <Input
          type={showPassword ? "text" : "password"}
          name={field.name}
          id={field.name}
          defaultValue={field.default}
          onChange={onChange}
          required={field.required}
        />

        <InputGroupAddon
          addonType="append"
          onClick={() => setShowPassword(!showPassword)}
        >
          <i
            className={classNames({
              "ri-eye-line": showPassword,
              "ri-eye-off-line": !showPassword,
              "text-dark": !showPassword,
            })}
          ></i>
        </InputGroupAddon>
      </InputGroup>

      {error && <p className="text-danger mt-1">{error}</p>}
    </FormGroup>
  );
}

export default memo(InputPassword);
