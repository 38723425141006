import React, { Component } from "react";
import AgentList from "./AgentList";
import { del, get } from "../../../utils/API";
import Swal from "sweetalert2";

class AgentListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filter: {},
      model: {},
      fields: [],
      isReady: false,
    };
  }
  componentDidMount() {
    this.getConfig();
  }

  getConfig() {
    const { model } = this.props.match.params;
    const config = require(`../../../models/${model}.js`).default;

    let fields = [];
    for (const field of config.field) {
      if (field.list == false) continue;

      if (field.image) {
        fields.push({
          name: field.label,
          cell: (row) => (
            <img
              src={row[field.name]}
              className="img-fluid"
              onError="https://images.unsplash.com/photo-1477959858617-67f85cf4f1df?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
            />
          ),
          center: true,
        });
      } else {
        fields.push(field);
      }
    }
    this.setState(
      {
        model: config,
        fields: fields,
        isReady: true,
      },
      () => this.getData()
    );
  }

  getData() {
    get(this.state.model.listEndpoint).then(({ data }) => {
      this.setState((prev) => ({
        data: data,
      }));
    });
  }

  navigateToAdd() {
    this.props.history.push(`/app/${this.state.model.name}/add/admin`);
  }

  onDelete(item) {
    Swal.fire({
      title: "Hapus data?",
      text: `Apakah anda yakin ingin menghapus data ${item.name}?`,
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Batal",
      confirmButtonText: "Ya, Hapus",
      confirmButtonColor: "#ff0000",
    }).then((response) => {
      console.log(response);
      if (response.isConfirmed) {
        del(this.state.model.deleteEndpoint.replace(":id", item.id))
          .then(() => {
            Swal.fire({
              title: "Berhasil",
              text: "Data berhasil dihapus",
              icon: "success",
            });
          })
          .catch((err) => {
            Swal.fire({
              title: "Gagal",
              text: err.error_message,
              icon: "error",
            });
          });
      }
    });
  }

  onEdit(item) {
    this.props.history.push(
      `/app/${this.state.model.name}/edit/${item.id}/admin`
    );
  }

  onView(item) {
    this.props.history.push(
      `/app/${this.state.model.name}/view/${item.id}/admin`
    );
  }

  render() {
    return (
      <AgentList
        state={this.state}
        getData={this.getData.bind(this)}
        onDelete={this.onDelete.bind(this)}
        navigateToAdd={this.navigateToAdd.bind(this)}
        onEdit={this.onEdit.bind(this)}
        onView={this.onView.bind(this)}
      />
    );
  }
}

export default AgentListController;
