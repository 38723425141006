import React, { Component } from "react";
import Swal from "sweetalert2";
import { edit, get, post } from "../../../utils/API";
import GenericEdit from "./GenericEdit";

class GenericEditController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      fields: [],
      isReady: false,
      form: {},
      lookupData: [],

      submit: false,

      initialData: {},
      errors: {},
    };

    // this.lookup = this.lookup.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  getConfig() {
    let fields = [];
    let initialForm = {};
    let { initialData } = this.state;

    for (const field of this.state.model.fields) {
      if (field.edit.allow !== true) continue;

      fields.push({
        ...field.edit,
        name: field.name,
        label: field.label,
      });

      var displayFormat = field.edit.displayFormat;

      console.log("DISPLAY", field);
      for (const displayIdx of field.edit.display.keys()) {
        displayFormat = displayFormat.replace(
          new RegExp(`\\%${displayIdx}%`, "gm"),
          initialData[field.edit.display[displayIdx]]
        );
      }

      if (!initialData[field.name] || initialData[field.name] == null) {
        initialForm[field.name] = "";
      } else {
        initialForm[field.name] = displayFormat;
      }

      // if (field.add.type !== "lookup") {
      //   console.log(this.state.initialData[field.list.viewDisplay]);
      // }
    }

    this.setState({ fields: fields, form: initialForm });
    // let fields = [];
    // for (const field of this.state.model.field) {
    //   if (field.edit == false && field.add == false) continue;
    //   fields.push(field);

    //   if (field.type === "lookup") {
    //     var displayFormat = field.relation_display_format;
    //     for (const defaultField of Object.keys(this.state.initialData)) {
    //       console.log("test", defaultField);
    //       displayFormat = displayFormat.replace(
    //         new RegExp(`\\%${defaultField}%`, "gm"),
    //         this.state.initialData[defaultField]
    //       );
    //     }
    //     console.log(displayFormat);

    //     let stateName = `selected_${field.name}`;

    //     this.setState({
    //       [stateName]: {
    //         label: displayFormat,
    //         value: this.state.initialData[field.name],
    //       },
    //     });
    //   }
    // }
    // this.setState({
    //   fields: fields,
    //   isReady: true,
    // });
  }

  getData() {
    const { model } = this.props.match.params;
    const { id } = this.props.match.params;

    const modelSrc = require(`../../../models/${model}.js`).default;

    // console.log(this.props.history);

    get(modelSrc.viewEndpoint.replace(":id", id)).then((response) => {
      // console.log("RESPONSE", response);
      this.setState(
        {
          initialData: response || {},
          model: modelSrc,
        },
        () => this.getConfig()
      );
    });

    // this.setState({
    //   model: modelSrc,
    //   fields: fields,
    // });
    // get(config.viewEndpoint.replace(":id", id)).then((response) => {
    //   this.setState(
    //     { initialData: response, model: config, form: response },
    //     () => this.getConfig()
    //   );
    // });
  }

  onChangeInput(key, value) {
    this.setState((prev) => ({
      form: {
        ...prev.form,
        [key]: value,
      },
    }));
  }

  lookup(inputValue, name) {
    return new Promise(async (resolve) => {
      get("whatsapp", { src: inputValue }).then((result) => {
        let response = [];
        for (const data of result.data) {
          response.push({
            label: `${data.name} (${data.phone})`,
            value: data.id,
          });
        }
        resolve(response);
      });
    });
  }

  onSubmit() {
    this.setState({ submit: true });

    let endpoint = this.state.model.editEndpoint;
    let payload = {
      ...this.state.form,
    };
    if (endpoint.includes(":id")) {
      endpoint = this.state.model.editEndpoint.replace(
        ":id",
        this.state.initialData.id
      );
    } else {
      payload.id = this.state.initialData.id;
    }
    edit(endpoint, payload)
      .then(() => {
        Swal.fire({
          text: `${this.state.model.label} berhasil diperbarui`,
          title: "Berhasil",
          icon: "success",
        });

        this.setState({ submit: false });
        this.props.history.goBack();
      })
      .catch((err) => {
        Swal.fire({
          text: err.error_message,
          title: "Gagal",
          icon: "error",
        });

        this.setState({ submit: false });
      });
  }

  onBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <GenericEdit
        state={this.state}
        onChangeInput={this.onChangeInput.bind(this)}
        // lookup={(value, name) => this.lookup(value, name)}
        onSubmit={this.onSubmit.bind(this)}
        onBack={this.onBack.bind(this)}
        lookup={this.lookup.bind(this)}
      />
    );
  }
}
export default GenericEditController;
