import moment from "moment";
import React, { Fragment, memo } from "react";
import { useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Tooltip,
  Input,
} from "reactstrap";
import FormPagination from "../../components/forms/FormPagination";
import Breadcrumb from "../../layout/breadcrumb";
import { toIDR, avatarHelper } from "../../utils/Formatter";

function BroadcastList({
  state,
  onAdd,
  onView,
  onEdit,
  onDelete,
  onCustomAction,
  onChangePage,
  onSearch,
}) {
  const onChangePagination = useCallback((limit, offset) => {
    onChangePage(limit, offset);
  });
  return (
    <Fragment>
      <Breadcrumb parent="" title={`Broadcast`} />
      <Container fluid={true} className="generic-list-page broadcast-list-page">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex flex-row justify-content-between">
                <h5>Daftar Siaran</h5>
                <Button size="md" color="primary" onClick={() => onAdd()}>
                  Buat Siaran
                </Button>
              </CardHeader>

              <div className="table-filter row"></div>
              <div className="table-responsive">
                {/* {Array.isArray(state.data) && state.data.length > 0 && (
                  <pre>{JSON.stringify(state.data[0])}</pre>
                )} */}
                <Table className="generic-list">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Tanggal</th>
                      <th>Jam</th>
                      <th>Pesan</th>
                      <th className="text-center">Penerima</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.data.map((b, i) => (
                      <tr key={`${b.id}-${i}`}>
                        <td>{i + 1}</td>
                        <td>
                          {b.schedule_at &&
                            moment(b.schedule_at).format("D MMMM YYYY")}
                          {!b.schedule_at && "-"}
                        </td>

                        <td>
                          {b.schedule_at &&
                            moment(b.schedule_at).format("HH:mm:ss")}
                          {!b.schedule_at && "-"}
                        </td>

                        <td style={{ width: "40%" }}>
                          <p className="max-line-3">{b.message}</p>
                        </td>
                        <td className="text-center">
                          <div className="contacts d-inline-block avatar-group">
                            <ul>
                              {b.detail.map((detail, i) => {
                                if (i > 3) return null;
                                return (
                                  <li
                                    className="d-inline-block "
                                    key={`${b.id}-${i}`}
                                  >
                                    <div
                                      className={`avatar-helper ${i}`}
                                      id={`tooltip-${detail.name}`}
                                    >
                                      {i > 2 && `+${b.total_recipient - 3}`}
                                      {i <= 2 && avatarHelper(detail.name)}
                                    </div>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </td>

                        <td
                          className="p-relative"
                          style={{ minWidth: "100px" }}
                        >
                          <div className="action-button">
                            <Button
                              size="xs"
                              color="light"
                              className="mx-1"
                              onClick={() => onView(b)}
                            >
                              <i className="ri-information-line text-dark"></i>
                            </Button>

                            <Button
                              size="xs"
                              color="light"
                              className="mx-1"
                              onClick={() => onEdit(b)}
                            >
                              <i className="ri-edit-2-line text-primary"></i>
                            </Button>

                            {/* <Button
                              size="xs"
                              color="light"
                              className="mx-1"
                              onClick={() => onDelete(b)}
                            >
                              <i className="ri-delete-bin-6-line text-danger"></i>
                            </Button> */}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {state.totalRecord !== null && (
                <FormPagination
                  totalRecord={state.totalRecord}
                  limit={state.filter.limit}
                  offset={state.filter.offset}
                  onChange={onChangePagination}
                />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default memo(BroadcastList);
