import React, { Component } from "react";
import Swal from "sweetalert2";
import { get } from "../../utils/API";
import BroadcastList from "./BroadcastList";

class BroadcastListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isReady: false,
      refresh: false,
      filter: {
        limit: 10,
        offset: 0,
        search: "",
      },
      totalRecord: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    get("broadcast", this.state.filter)
      .then((response) => {
        this.setState({
          data: response.data,
          isReady: true,
          refresh: false,
          totalRecord: response.record,
        });
      })
      .catch((err) => {
        Swal.fire("Gagal mengambil data", err.error_message, "error");
      });
  }

  onView(data) {
    this.props.history.push(`/blast/broadcast/view/${data.id}/admin`);
  }

  onAdd() {
    this.props.history.push(`/blast/broadcast/add/admin`);
  }

  onDelete(data) {}

  onEdit(data) {
    this.props.history.push(`/blast/broadcast/edit/${data.id}/admin`);
  }

  onChangePage(limit, offset) {
    // console.log("limit", limit, offset);
    this.setState(
      (prev) => ({
        filter: {
          ...prev.filter,
          limit: limit,
          offset: offset,
        },
        refresh: true,
      }),
      () => this.getData()
    );
  }

  render() {
    return (
      <BroadcastList
        state={this.state}
        onView={this.onView.bind(this)}
        onEdit={this.onEdit.bind(this)}
        onDelete={this.onDelete.bind(this)}
        onAdd={this.onAdd.bind(this)}
        onChangePage={this.onChangePage.bind(this)}
      />
    );
  }
}

export default BroadcastListController;
