import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  FormFeedback,
  CustomInput,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumb from "../../../layout/breadcrumb";
import AsyncSelect from "react-select/async";

function GenericEdit({
  state,
  onChangeInput,
  lookup,
  lookupTest,
  onSubmit,
  onBack,
}) {
  return (
    <Fragment>
      <Breadcrumb parent="" title={state.model.label} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Card>
                <CardHeader className="d-flex flex-row justify-content-between">
                  <h5>Edit {state.model.label}</h5>

                  <Button color="light" onClick={() => onBack()}>
                    Kembali
                  </Button>
                </CardHeader>
                <CardBody>
                  {state.fields
                    .filter((v) => v.type !== "switch")
                    .map((field, i) => {
                      // if (["lookup"].includes(field.type)) {
                      //   return (
                      //     <FormGroup key={`form-${i}`}>
                      //       <Label
                      //         className={classnames({
                      //           required: field.required,
                      //         })}
                      //         for={field.name}
                      //       >
                      //         {field.label}
                      //       </Label>
                      //       <AsyncSelect
                      //         defaultOptions
                      //         // loadOptions={lookup}
                      //         loadOptions={(event) => lookupTest(event, field)}
                      //         onChange={(event) =>
                      //           onChangeInput(field.name, event.value)
                      //         }
                      //       />
                      //       {state.errors[field.name] && (
                      //         <p className="text-danger mt-1">
                      //           {state.errors[field.name]}
                      //         </p>
                      //       )}
                      //     </FormGroup>
                      //   );
                      // }

                      if (["select"].includes(field.type)) {
                        return (
                          <FormGroup key={`form-${i}`}>
                            <Label
                              className={classnames({
                                required: field.required,
                              })}
                              for={field.name}
                            >
                              {field.label}
                            </Label>
                            <Input
                              type="select"
                              name={field.name}
                              id={field.name}
                              onChange={(event) =>
                                onChangeInput(field.name, event.target.value)
                              }
                              value={state.form[field.name]}
                              required={field.required}
                            >
                              <option>- Pilih {field.label} --</option>
                              {field.options.map((option, i) => (
                                <option
                                  key={`${field.name}-${i}`}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        );
                      }
                      return (
                        <FormGroup key={`form-${i}`}>
                          <Label
                            className={classnames({
                              required: field.required,
                            })}
                            for={field.name}
                          >
                            {field.label}
                          </Label>
                          <Input
                            type={field.type}
                            name={field.name}
                            id={field.name}
                            value={state.form[field.name]}
                            onChange={(event) =>
                              onChangeInput(
                                event.target.name,
                                event.target.value
                              )
                            }
                            required={field.required}
                          />

                          {state.errors[field.name] && (
                            <p className="text-danger mt-1">
                              {state.errors[field.name]}
                            </p>
                          )}
                        </FormGroup>
                      );
                    })}

                  {state.fields.filter((v) => v.type === "switch").length >
                    0 && (
                    <Row>
                      <Col md={6} lg={7}>
                        <Row>
                          {state.fields
                            .filter((v) => v.type === "switch")
                            .map((field, i) => (
                              <Col md={6} key={`form-${i}`}>
                                <FormGroup row className="pl-4">
                                  {/* <Label
                                    for={field.name}
                                    md={6}
                                    lg={4}
                                  >
                                    {field.label}
                                  </Label>
                                  <Col md={6} lg={8}> */}
                                  <CustomInput
                                    onChange={(e) =>
                                      onChangeInput(
                                        field.name,
                                        e.target.checked ? "1" : "0"
                                      )
                                    }
                                    id={`${field.name}-${i}`}
                                    name={field.name}
                                    label={field.label}
                                    type="switch"
                                    checked={state.form[field.name] == "1"}
                                  />
                                  {/* </Col> */}
                                </FormGroup>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </CardBody>

                <CardFooter className="d-flex flex-row justify-content-end">
                  <Button
                    color="primary"
                    size="md"
                    type="submit"
                    disabled={state.submit}
                  >
                    Edit
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GenericEdit;
