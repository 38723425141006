import React, { Fragment } from "react";
import Breadcrumbs from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  CardFooter,
  Badge,
} from "reactstrap";
import { formatPhone, filterMessage } from "../../utils/Formatter";
import moment from "moment";
import FormPagination from "../../components/forms/FormPagination";

function MessageList({ state, onView, onDelete, onPageChange }) {
  return (
    <Fragment>
      <Breadcrumbs parent="" title={`Pesan`} />
      <Container fluid={true} className="message-list-page">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex flex-row justify-content-between pb-4 pt-4">
                <h5>Pesan</h5>
              </CardHeader>

              <div className="table-responsive">
                <Table className="message-table">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th className="text-center">Dari</th>
                      <th className="text-center">Ke</th>
                      <th>Pesan</th>
                      <th>Tanggal</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {state.data.map((d, i) => (
                      <tr key={`${d.id}-${i}`}>
                        <td>{i + 1}</td>
                        {d.flag === "I" ? (
                          <td>
                            <p className="text-center mb-1">
                              {formatPhone(d.whatsapp_name)}
                            </p>
                            {d.whatsapp_name !== d.whatsapp_number && (
                              <p className="text-center small text-muted">
                                ({formatPhone(d.whatsapp_number)})
                              </p>
                            )}
                          </td>
                        ) : (
                          <td>
                            <p className="text-center mb-1">
                              {formatPhone(d.name)}
                            </p>
                            {d.name !== d.phone && (
                              <p className="text-center small text-muted">
                                ({formatPhone(d.phone)})
                              </p>
                            )}
                          </td>
                        )}

                        {d.flag === "I" ? (
                          <td>
                            <p className="text-center mb-1">
                              {formatPhone(d.name)}
                            </p>
                            <p className="text-center small text-muted">
                              ({formatPhone(d.phone)})
                            </p>
                          </td>
                        ) : (
                          <td>
                            <p className="text-center mb-1">
                              {formatPhone(d.whatsapp_name)}
                            </p>
                            <p className="text-center small text-muted">
                              ({formatPhone(d.whatsapp_number)})
                            </p>
                          </td>
                        )}

                        <td>
                          {d.message_type === "TEXT" && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: filterMessage(d.message),
                              }}
                            />
                          )}
                          {d.message_type !== "TEXT" && d.message_type}
                        </td>
                        <td>
                          {moment(d.created_at).format("D MMMM YYYY HH:mm:ss")}
                        </td>

                        <td
                          style={{
                            width: "100px",
                          }}
                          className="p-relative"
                        >
                          <div className="action-button">
                            {/* <Button
                              size="xs"
                              color="light"
                              className="mx-1"
                              onClick={() => onView(d)}
                            >
                              <i className="ri-information-line text-dark"></i>
                            </Button> */}

                            <Button
                              size="xs"
                              color="light"
                              className="mx-1"
                              onClick={() => onDelete(d)}
                            >
                              <i className="ri-delete-bin-6-line text-danger"></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              <CardFooter>
                <FormPagination
                  totalRecord={state.totalRecord}
                  limit={state.filter.limit}
                  offset={state.filter.offset}
                  onChange={(limit, offset) =>
                    onPageChange({
                      limit: limit,
                      offset: offset,
                    })
                  }
                />
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
export default MessageList;
