import React, { Component } from "react";
import Swal from "sweetalert2";
import { get, post } from "../../../utils/API";
import { validate } from "../../../utils/Validate";
import GenericAdd from "./GenericAdd";
import { toast } from "react-toastify";

class GenericAddController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      fields: [],
      isReady: false,
      form: {},
      lookupData: [],

      submit: false,
      errors: {},
    };

    // this.lookup = this.lookup.bind(this);
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig() {
    const { model } = this.props.match.params;
    const config = require(`../../../models/${model}.js`).default;

    let fields = [];
    let defaultFormValue = {};
    for (const field of config.fields) {
      if (!field.add) {
        continue;
      }
      if (field.add.allow !== true) continue;
      fields.push({
        ...field.add,
        label: field.label,
        name: field.name,
      });

      if (field.add.default) {
        defaultFormValue[field.name] = field.add.default;
      }
    }
    this.setState({
      model: config,
      fields: fields,
      form: defaultFormValue,
      isReady: true,
    });
  }

  onChangeInput(key, value) {
    this.setState((prev) => ({
      form: {
        ...prev.form,
        [key]: value,
      },
      errors: {
        ...prev.errors,
        [key]: null,
      },
    }));
  }

  lookup(inputValue, name) {
    return new Promise(async (resolve) => {
      get("whatsapp", { src: inputValue })
        .then((result) => {
          let response = [];
          for (const data of result.data) {
            response.push({
              label: `${data.name} (${data.phone})`,
              value: data.id,
            });
          }
          resolve(response);
        })
        .catch((err) => {
          Swal.fire("Gagal", err.error_message, "error");
          resolve([]);
        });
    });
  }
  lookupTest(inputValue, field) {
    return new Promise(async (resolve) => {
      get(field.lookupEndpoint, { search: inputValue })
        .then((result) => {
          let response = [];
          console.log("result", result);

          for (const data of result.data) {
            var displayFormat = field.displayFormat;
            for (const index of field.display.keys()) {
              displayFormat = displayFormat.replace(
                new RegExp(`\\%${index}%`, "gm"),
                data[field.display[index]]
              );
            }

            response.push({
              label: displayFormat,
              value: data.id,
            });
          }
          resolve(response);
        })

        .catch((err) => {
          Swal.fire("Gagal", err.error_message, "error");
          resolve([]);
        });
    });
  }

  onSubmit() {
    let validations = {};
    for (const field of this.state.fields) {
      validations[field.name] = {
        required: field.required,
        validate: field.validate,
        label: field.label,
      };

      if (field.minLength) {
        validations[field.name].minLength = field.minLength;
      }
    }

    let errors = validate(this.state.form, validations);

    if (Object.keys(errors).length > 0) {
      console.log("err", errors);
      this.setState({ errors: errors });
      return false;
    }

    this.setState({ submit: true });
    post(this.state.model.addEndpoint, this.state.form)
      .then((response) => {
        // Swal.fire({
        //   text: `${this.state.model.label} berhasil ditambahkan`,
        //   title: "Berhasil",
        //   icon: "success",
        // });
        // toast.success()
        toast.success(`${this.state.model.label} berhasil ditambahkan`);

        this.setState({ submit: false });
        this.props.history.replace(
          `/app/${this.state.model.name}/view/${response.id}/admin`
        );
      })
      .catch((err) => {
        Swal.fire({
          text: err.error_message,
          title: "Gagal",
          icon: "error",
        });

        this.setState({ submit: false });
      });
  }

  onBack() {
    this.props.history.goBack();
  }

  render() {
    if (this.state.isReady) {
    }
    return (
      <GenericAdd
        state={this.state}
        onChangeInput={this.onChangeInput.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        lookup={this.lookup.bind(this)}
        lookupTest={this.lookupTest.bind(this)}
        onBack={this.onBack.bind(this)}
      />
    );
  }
}
export default GenericAddController;
