import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  FormFeedback,
  CustomInput,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumb from "../../../layout/breadcrumb";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import moment from "moment";
import InputPassword from "./components/InputPassword";

function GenericAdd({
  state,
  onChangeInput,
  lookup,
  lookupTest,
  onSubmit,
  onBack,
}) {
  if (!state.isReady) return null;
  return (
    <Fragment>
      <Breadcrumb parent={state.model.label} title={`${state.model.label}`} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Card>
                <CardHeader className="d-flex flex-row justify-content-between">
                  <h5>Tambah {state.model.label}</h5>

                  <Button color="light" onClick={() => onBack()}>
                    Kembali
                  </Button>
                </CardHeader>
                <CardBody>
                  {state.fields
                    .filter((v) => v.type !== "switch")
                    .map((field, i) => {
                      if (["lookup"].includes(field.type)) {
                        return (
                          <FormGroup key={`form-${i}`}>
                            <Label
                              className={classnames({
                                required: field.required,
                              })}
                              for={field.name}
                            >
                              {field.label}
                            </Label>
                            <AsyncSelect
                              defaultOptions
                              isMulti={
                                typeof field.multiple === "boolean"
                                  ? field.multiple
                                  : false
                              }
                              className="select-lookup"
                              // loadOptions={lookup}
                              loadOptions={(event) => lookupTest(event, field)}
                              onChange={(event) => {
                                if (
                                  typeof field.multiple === "boolean" &&
                                  field.multiple === true
                                ) {
                                  let formValue = [];
                                  for (const fieldValue of event) {
                                    formValue.push(fieldValue.value);
                                  }
                                  onChangeInput(field.name, formValue);
                                } else {
                                  onChangeInput(field.name, event.value);
                                }
                              }}
                            />
                            {state.errors[field.name] && (
                              <p className="text-danger mt-1">
                                {state.errors[field.name]}
                              </p>
                            )}
                          </FormGroup>
                        );
                      }

                      if (["radio"].includes(field.type)) {
                        return (
                          <FormGroup
                            tag="fieldset"
                            onChange={(event) => {
                              onChangeInput(field.name, event.target.value);
                            }}
                          >
                            <Label
                              className={classnames({
                                required: field.required,
                              })}
                              for={field.name}
                            >
                              {field.label}
                            </Label>

                            <Row>
                              {field.options.map((option, i) => (
                                <Col md={4} lg={3} sm={6}>
                                  <FormGroup
                                    check
                                    key={`form-${field.name}-${i}`}
                                    className="form-radio-custom"
                                  >
                                    <Label check className="w-100">
                                      <Input
                                        type="radio"
                                        name={field.name}
                                        value={option.value}
                                      />
                                      {option.label}
                                    </Label>
                                  </FormGroup>
                                </Col>
                              ))}
                            </Row>
                          </FormGroup>
                        );
                      }

                      if (["date"].includes(field.type)) {
                        return (
                          <FormGroup key={`form-${i}`}>
                            <Label
                              className={classnames({
                                required: field.required,
                              })}
                              for={field.name}
                            >
                              {field.label}
                            </Label>
                            <DatePicker
                              selected={
                                state.form[field.name]
                                  ? new Date(state.form[field.name])
                                  : false
                              }
                              required={field.required}
                              onChange={(date) => {
                                let dateValue = moment(date).format(
                                  field.dateFormat
                                    ? field.dateFormat
                                    : "YYYY-MM-DD"
                                );

                                // console.log(dateValue)
                                onChangeInput(field.name, dateValue);
                              }}
                              inline
                            />
                          </FormGroup>
                        );
                      }

                      if (["select"].includes(field.type)) {
                        return (
                          <FormGroup key={`form-${i}`}>
                            <Label
                              className={classnames({
                                required: field.required,
                              })}
                              for={field.name}
                            >
                              {field.label}
                            </Label>
                            <Input
                              type="select"
                              name={field.name}
                              id={field.name}
                              onChange={(event) =>
                                onChangeInput(field.name, event.target.value)
                              }
                              required={field.required}
                            >
                              <option>- Pilih {field.label} --</option>
                              {field.options.map((option, i) => (
                                <option
                                  key={`${field.name}-${i}`}
                                  value={option.value}
                                >
                                  {option.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        );
                      }

                      if (["password"].includes(field.type)) {
                        return (
                          <InputPassword
                            field={field}
                            onChangeInput={(key, value) =>
                              onChangeInput(key, value)
                            }
                            error={state.errors[field.name]}
                          />
                        );
                      }
                      return (
                        <FormGroup key={`form-${i}`}>
                          <Label
                            className={classnames({
                              required: field.required,
                            })}
                            for={field.name}
                          >
                            {field.label}
                          </Label>
                          <Input
                            type={field.type}
                            name={field.name}
                            id={field.name}
                            defaultValue={field.default}
                            onChange={(event) =>
                              onChangeInput(
                                event.target.name,
                                event.target.value
                              )
                            }
                            required={field.required}
                          />

                          {state.errors[field.name] && (
                            <p className="text-danger mt-1">
                              {state.errors[field.name]}
                            </p>
                          )}
                        </FormGroup>
                      );
                    })}

                  {state.fields.filter((v) => v.type === "switch").length >
                    0 && (
                    <Row>
                      <Col md={6} lg={7}>
                        <Row>
                          {state.fields
                            .filter((v) => v.type === "switch")
                            .map((field, i) => (
                              <Col md={6} key={`form-${i}`}>
                                <FormGroup row className="pl-4">
                                  {/* <Label
                                    for={field.name}
                                    md={6}
                                    lg={4}
                                  >
                                    {field.label}
                                  </Label>
                                  <Col md={6} lg={8}> */}
                                  <CustomInput
                                    onChange={(e) =>
                                      onChangeInput(
                                        field.name,
                                        e.target.checked ? "1" : "0"
                                      )
                                    }
                                    id={`${field.name}-${i}`}
                                    name={field.name}
                                    label={field.label}
                                    type="switch"
                                    checked={state.form[field.name] == "1"}
                                  />
                                  {/* </Col> */}
                                </FormGroup>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </CardBody>

                <CardFooter className="d-flex flex-row justify-content-end">
                  <Button
                    color="primary"
                    size="md"
                    type="submit"
                    disabled={state.submit}
                  >
                    Tambah
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GenericAdd;
