const model = {
  name: "users",
  label: "Pengguna",
  addEndpoint: "users",
  listEndpoint: "users",
  editEndpoint: "users/:id",
  viewEndpoint: "users/:id",
  allowed: ["create", "read", "edit", "delete"],
  list: true,
  edit: true,
  add: true,
  view: true,
  delete: false,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: { allow: false },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },
    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },

    {
      name: "phone",
      label: "No HP",
      filter: false,
      list: {
        allow: true,
        display: ["phone"],
        displayFormat: "%0%",
        validate: "phone",
        viewDisplay: "phone",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "phone",
        type: "phone",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },
    {
      name: "role_name",
      label: "Hak Akses",
      filter: false,
      list: {
        allow: true,
        display: ["role_name"],
        displayFormat: "%0%",
        viewDisplay: "role_name",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },

    {
      name: "email",
      label: "Email",
      filter: false,
      list: {
        allow: true,
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "email",
        type: "email",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },

    {
      name: "active",
      label: "Status",
      filter: false,
      list: {
        allow: true,
        display: ["active"],
        viewDisplay: "active",
        displayFormat: "%0%",
        image: true,
        type: "badge",
        wrapperClass: "text-center",
        viewValues: [
          {
            label: "Aktif",
            value: 1,
            color: "success",
          },
          {
            label: "Tidak Aktif",
            value: 0,

            color: "secondary",
          },
        ],
      },
      view: { allow: false },

      add: { allow: false },
      edit: { allow: false },
    },

    {
      name: "whatsapp",
      label: "Whatsapp",
      filter: false,
      list: {
        allow: true,
        display: ["phone"],
        displayFormat: "%1%",
        viewDisplay: "whatsapp",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },
    {
      name: "whatsapp_id",
      label: "Whatsapp",
      filter: false,
      list: {
        allow: false,
        display: ["name", "phone"],
        displayFormat: "%0%",
        viewDisplay: "whatsapp_phone",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name", "phone"],
        displayFormat: "%0% (%1%)",
        lookupEndpoint: "whatsapp",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      delete: {
        allow: true,
      },
      view: { allow: false },
    },

    {
      name: "role_id",
      label: "Hak Akses",
      filter: false,
      list: {
        allow: false,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "roles",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: false,
        validate: "integer",
        type: "lookup",
        display: ["role_name"],
        displayFormat: "%0%",
        lookupEndpoint: "roles",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "integer",
        type: "lookup",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        lookupEndpoint: "roles",
        display: ["roles"],
        displayFormat: "%0%",
        viewDisplay: "roles",
      },
      view: { allow: false },

      delete: {
        allow: true,
      },
    },
    {
      name: "password",
      label: "Password",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "string",
        type: "password",
        maxLength: null,
        minLength: 8,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: false,
        validate: "string",
        type: "password",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
      },
      view: { allow: false },

      delete: {
        allow: true,
      },
    },
  ],
};

export default model;
