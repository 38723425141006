const model = {
  name: "whatsapp",
  label: "Whatsapp",
  add: true,
  edit: true,
  delete: true,
  view: true,
  list: true,
  listEndpoint: "whatsapp",
  addEndpoint: "whatsapp/register",
  deleteEndpoint: "whatsapp/:id",
  viewEndpoint: "whatsapp/:id",
  editEndpoint: "whatsapp/:id",
  filter: "",
  masterdetail: false,
  field: [
    {
      name: "id",
      label: "ID",
      primary: true,
      type: "number",
      validate: "integer",
      number: true,
      list: false,
      view: true,
      add: false,
      edit: false,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: false,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },
    {
      name: "name",
      label: "Nama",
      primary: false,
      type: "text",
      validate: "string",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },

    {
      name: "phone",
      label: "No WA",
      primary: false,
      type: "phone",
      validate: "phone",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: true,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: false,
      file: false,
    },

    {
      name: "photo",
      label: "",
      primary: false,
      type: "file",
      validate: "",
      number: false,
      list: true,
      view: true,
      add: false,
      edit: false,
      sort: false,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      default: "",
      image: true,
      file: false,
    },
    {
      name: "active",
      label: "Status",
      primary: false,
      type: "select",
      validate: "string",
      number: false,
      list: true,
      view: true,
      add: true,
      edit: true,
      sort: false,
      filtersearch: false,
      relation: false,
      relation_table: "",
      relation_field: "",
      relation_display: [],
      parent_filter: [],
      required: true,
      readonly: false,
      disabled: false,
      options: [
        {
          label: "Aktif",
          value: "1",
        },
        {
          label: "Tidak Aktif",
          value: "0",
        },
      ],
      default: "1",
      image: false,
      file: false,
    },
  ],
};

export default model;
