const model = {
  name: "whatsapp",
  label: "Device",
  addEndpoint: "whatsapp/register",
  listEndpoint: "whatsapp",
  editEndpoint: "whatsapp/:id",
  viewEndpoint: "whatsapp/:id",
  deleteEndpoint: "whatsapp/:id",
  add: true,
  edit: true,
  list: true,
  delete: true,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        viewDisplay: "name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
    },

    {
      name: "code",
      label: "Kode",
      filter: false,
      list: {
        allow: true,
        display: ["code"],
        viewDisplay: "code",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
    },

    {
      name: "phone",
      label: "No HP",
      filter: false,
      list: {
        allow: true,
        display: ["phone"],
        viewDisplay: "phone",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },
    },

    {
      name: "subscription_service_id",
      label: "Subscription",
      filter: false,
      list: {
        allow: false,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "subscription_service",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name"],
        displayFormat: "%0%",
        lookupEndpoint: "subscription_service",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "integer",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "subscription_service",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
      },
    },

    {
      name: "duration_id",
      label: "Durasi",
      filter: false,
      list: {
        allow: false,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "duration",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name", "duration"],
        displayFormat: "%0% (%1% bulan)",
        lookupEndpoint: "service_duration",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "integer",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "duration",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
      },
    },

    {
      name: "payment_id",
      label: "Pembayaran",
      filter: false,
      list: {
        allow: false,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "payment_id",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name"],
        displayFormat: "%0%",
        lookupEndpoint: "payment_methode",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "integer",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "payment_id",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
      },
    },
  ],
};

export default model;
