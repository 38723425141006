import classNames from "classnames";
import React, { Fragment, memo } from "react";
import { useCallback } from "react";
import { Card } from "reactstrap";
function NotFound() {
  return (
    <Card>
      <h1>Not Found</h1>
    </Card>
  );
}

export default memo(NotFound);
