const model = {
  name: "broadcasts",
  label: "Broadcast",
  fields: [
    {
      name: "attachment",
      label: "Lampir",
      filter: false,
      list: {
        allow: true,
        display: ["attachment"],
        displayFormat: "%0%",
        renderHtml: false,
        sortable: false,
      },
      add: {
        allow: true,
        validate: "string",
        type: "file",
        max: 1,
        fileFormat: ["jpg", "png", "jpeg"],
        required: false,
        renderBefore: "",
      },

      edit: {
        allow: true,
      },
    },
  ],
};

export default model;
