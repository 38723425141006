import React, { Fragment, useState, useEffect } from "react";
import man from "../../assets/images/dashboard/profile.jpg";
import { LogIn, Bell, Minimize, Search } from "react-feather";
import { useHistory } from "react-router-dom";
import { firebase_app } from "../../data/config";
import { useAuth0 } from "@auth0/auth0-react";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import useLocalStorage from "../../hooks/useLocalStorage";

import { Admin, LogOut } from "../../constant";

import en from "../../assets/i18n/en.json";
import es from "../../assets/i18n/es.json";
import pt from "../../assets/i18n/pt.json";
import fr from "../../assets/i18n/fr.json";
import du from "../../assets/i18n/du.json";
import cn from "../../assets/i18n/cn.json";
import ae from "../../assets/i18n/ae.json";

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = (props) => {
  const history = useHistory();

  const [profile, setProfile] = useState("");
  const [name, setName] = useState("");
  const [searchresponsive, setSearchresponsive] = useState(false);
  const [langdropdown, setLangdropdown] = useState(false);
  const [moonlight, setMoonlight] = useState(false);
  const [selected, setSelected] = useState("en");
  const [cartDropdown, setCartDropDown] = useState(false);
  const [notificationDropDown, setNotificationDropDown] = useState(false);
  const [chatDropDown, setChatDropDown] = useState(false);
  const [storage, setStorage] = useLocalStorage("profile");

  // auth0 profile
  const { logout } = useAuth0();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"));

  //full screen function
  function goFull() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  const SeacrhResposive = (searchresponsive) => {
    if (searchresponsive) {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.add("open");
      document.querySelector(".more_lang").classList.remove("active");
    } else {
      setSearchresponsive(!searchresponsive);
      document.querySelector(".search-full").classList.remove("open");
    }
  };

  const LanguageSelection = (language) => {
    if (language) {
      setLangdropdown(!language);
    } else {
      setLangdropdown(!language);
    }
  };

  const MoonlightToggle = (light) => {
    if (light) {
      setMoonlight(!light);
      document.body.className = "light";
      localStorage.setItem("layout_version", "light");
    } else {
      setMoonlight(!light);
      document.body.className = "dark-only";
      localStorage.setItem("layout_version", "dark-only");
    }
  };
  return (
    <Fragment>
      <div className="nav-right col-8 pull-right right-header p-0">
        <ul className="nav-menus">
          <li>
            <span className="header-search">
              <Search onClick={() => SeacrhResposive(searchresponsive)} />
            </span>
          </li>
          <li className="onhover-dropdown">
            <div
              className="notification-box"
              onClick={() => setNotificationDropDown(!notificationDropDown)}
            >
              <Bell />
              <span className="badge badge-pill badge-secondary">2</span>
            </div>
            <ul
              className={`notification-dropdown onhover-show-div ${
                notificationDropDown ? "active" : ""
              }`}
            ></ul>
          </li>
          <li>
            <div className="mode" onClick={() => MoonlightToggle(moonlight)}>
              <i
                className={`fa ${moonlight ? "fa-lightbulb-o" : "fa-moon-o"}`}
              ></i>
            </div>
          </li>
          <li className="maximize">
            <a className="text-dark" onClick={goFull}>
              <Minimize />
            </a>
          </li>
          <li className="profile-nav onhover-dropdown p-0">
            <div className="media profile-media">
              <img
                className="b-r-10"
                src={storage ? storage.photo_thumbnail : null}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = require("../../assets/images/User.jpg");
                }}
                alt=""
              />
              <div className="media-body">
                <span>{authenticated ? auth0_profile.name : name}</span>
                <p className="mb-0 font-roboto">
                  {Admin} <i className="middle fa fa-angle-down"></i>
                </p>
              </div>
            </div>
            <ul className="profile-dropdown onhover-show-div">
              <li
                onClick={() => {
                  localStorage.clear();
                  history.push(`${process.env.PUBLIC_URL}/login`, {
                    replace: true,
                  });
                }}
              >
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
