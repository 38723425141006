import React, { Component } from "react";
import Swal from "sweetalert2";
import { get, post } from "../../utils/API";
import { formatPhone } from "../../utils/Formatter";
import BroadcastCreate from "./BroadcastCreate";

class BroadcastCreateController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      fields: [],
      form: {
        attachment: "",
        schedule_date: "",
        schedule_time: "",
        message: "",
        // group_contact: [],
        contact: [],
      },
      isReady: true,
      schedule: false,
      submit: false,
      initialContacts: [],
    };
  }

  componentDidMount() {
    // this.getConfig()
  }

  onBack() {
    this.props.history.goBack();
  }

  lookupContact(inputValue) {
    return new Promise(async (resolve) => {
      get("contacts", { search: inputValue })
        .then((result) => {
          let response = [];
          for (const data of result.data) {
            response.push({
              label: `${data.name} (${formatPhone(data.phone)})`,
              value: data.id,
            });
          }

          resolve(response);
        })

        .catch((err) => {
          Swal.fire("Gagal", err.error_message, "error");
          resolve([]);
        });
    });
  }

  lookupGroupContact(inputValue) {
    return new Promise(async (resolve) => {
      get("group/contacts", { search: inputValue })
        .then((result) => {
          let response = [];
          for (const data of result.data) {
            response.push({
              label: data.name,
              value: data.id,
            });
          }

          resolve(response);
        })

        .catch((err) => {
          Swal.fire("Gagal", err.error_message, "error");
          resolve([]);
        });
    });
  }

  onSubmit() {
    let _payload = this.state.form;

    if (
      this.state.schedule === true &&
      (_payload.group_contact.length == 0 || _payload.contact.length == 0)
    ) {
      Swal.fire(
        "Pilih Kontak",
        "Anda harus memilih setidaknya 1 Kontak atau Grup Kontak",
        "info"
      );

      return false;
    }

    if (
      (this.state.schedule && !_payload.schedule_date) ||
      (this.state.schedule && !_payload.schedule_time)
    ) {
      Swal.fire(
        "Tentukan Tanggal",
        "Anda harus menentukan tanggal dan jam",
        "info"
      );
    }

    if (this.state.schedule === false) {
      _payload.schedule_date = "";
      _payload.schedule_time = "";
    }

    this.setState({ submit: false });

    post("broadcast", _payload)
      .then((response) => {
        this.setState({ submit: false });
        this.props.history.replace(
          `/blast/broadcast/view/${response.id}/admin`
        );
      })
      .catch((err) => {
        this.setState({ submit: false });

        if (err.error_message === "Whatsapp disconnect") {
          Swal.fire({
            title: "Gagal membuat siaran",
            html: "Whatsapp tidak terkoneksi, klik <strong>Device</strong> untuk menghubungkan atau reload Whatsapp",
            icon: "error",
            showCancelButton: true,
            showConfirmButton: true,
            cancelButtonText: "Tutup",
            confirmButtonText: "Device",
          }).then((response) => {
            if (response.isConfirmed) {
              this.props.history.push("/blast/device/list/admin");
            }
          });
        } else {
          Swal.fire({
            title: "Gagal membuat siaran",
            text: err.error_message,
            icon: "error",
          });
        }
      });
  }

  getConfig() {
    // const config = require(`../../../models/broadcast.js`).default;
    // let fields = [];
    // let defaultFormValue = {};
    // for (const field of config.fields) {
    //   if (!field.add) {
    //     continue;
    //   }
    //   if (field.add.allow !== true) continue;
    //   fields.push({
    //     ...field.add,
    //     label: field.label,
    //     name: field.name,
    //   });
    //   if (field.add.default) {
    //     defaultFormValue[field.name] = field.add.default;
    //   }
    // }
    // this.setState({
    //   model: config,
    //   fields: fields,
    //   form: defaultFormValue,
    //   isReady: true,
    // });
  }

  onChangeInput(key, value) {
    this.setState((prev) => ({
      form: {
        ...prev.form,
        [key]: value,
      },
    }));
  }

  setValue(key, value) {
    this.setState({
      [key]: value,
    });
  }

  render() {
    if (this.state.isReady === false) return null;
    return (
      <BroadcastCreate
        state={this.state}
        setValue={this.setValue.bind(this)}
        onChangeInput={this.onChangeInput.bind(this)}
        lookupContact={this.lookupContact.bind(this)}
        lookupGroupContact={this.lookupGroupContact.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        onBack={this.onBack.bind(this)}
      />
    );
  }
}

export default BroadcastCreateController;
