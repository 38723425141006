const model = {
  name: "group-contact",
  label: "Grup Kontak",
  addEndpoint: "group/contacts",
  listEndpoint: "group/contacts",
  editEndpoint: "group/contacts",
  viewEndpoint: "group/contacts/:id",
  deleteEndpoint: "group/contacts/:id",
  add: true,
  edit: false,
  list: true,
  delete: true,
  view: true,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        viewDisplay: "name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
    },

    {
      name: "description",
      label: "Deskripsi",
      filter: false,
      list: {
        allow: true,
        display: ["description"],
        viewDisplay: "description",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
    },

    {
      name: "active",
      label: "Status",
      filter: false,
      list: {
        allow: true,
        display: ["active"],
        viewDisplay: "active",
        displayFormat: "%0%",
        image: true,
        type: "badge",
        wrapperClass: "text-center",
        viewValues: [
          {
            label: "Aktif",
            value: 1,
            color: "success",
          },
          {
            label: "Tidak Aktif",
            value: 0,

            color: "secondary",
          },
        ],
      },
      add: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],

        default: "1",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
      delete: { allow: false },
      view: {
        allow: false,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
    },
  ],
};

export default model;
