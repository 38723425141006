import React, { useState, useEffect } from "react";
import man from "../assets/images/dashboard/profile.jpg";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Card,
  CardBody,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {
  firebase_app,
  googleProvider,
  facebookProvider,
  githubProvider,
  Jwt_token,
} from "../data/config";
import { handleResponse } from "../services/fack.backend";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { useHistory, withRouter } from "react-router-dom";
import { Facebook, GitHub } from "react-feather";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
  CreateAccount,
  FIREBASE,
  AUTH0,
  JWT,
  LoginWithJWT,
} from "../constant";
import { post } from "../utils/API";
import Swal from "sweetalert2";
import signup from "./signup";
import Signup from "./signup";
import classNames from "classnames";

const Logins = (props) => {
  const { loginWithRedirect } = useAuth0();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState("jwt");
  const [togglePassword, setTogglePassword] = useState(false);
  const [activePage, setActivePage] = useState("login");

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      return history.push("/blast/device/list/admin", { replace: true });
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("profileURL", value);
    localStorage.setItem("Name", name);
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await firebase_app
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(function () {
          setValue(man);
          setName("Emay Walter");
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  const googleAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(googleProvider)
        .then(function (result) {
          setName(result.user.displayName);
          setValue(result.user.photoURL);
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  const facebookAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(facebookProvider)
        .then(function (result) {
          setValue(result.user.photoURL);
          setName(result.user.displayName);
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  const githubAuth = async () => {
    try {
      firebase_app
        .auth()
        .signInWithPopup(githubProvider)
        .then(function (result) {
          setValue(result.user.photoURL);
          setName(result.additionalUserInfo.username);
          localStorage.setItem("token", Jwt_token);
          setTimeout(() => {
            props.history.push(`${process.env.PUBLIC_URL}/dashboard/default/`);
          }, 200);
        });
    } catch (error) {
      setTimeout(() => {
        toast.error(
          "Oppss.. The password is invalid or the user does not have a password."
        );
      }, 200);
    }
  };

  const loginWithJwt = (email, password) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: { email, password, mobile: 0 },
    };

    post("login", requestOptions.body)
      .then((data) => {
        localStorage.setItem("profile", JSON.stringify(data.profile));
        localStorage.setItem("token", data.token);
        localStorage.setItem("whatsapp", JSON.stringify(data.whatsapp));

        localStorage.setItem("permissions", JSON.stringify(data.permission));
        setValue(data.profile.id);
        setName(data.profile.name);
        localStorage.setItem("layout", "admin");

        // console.group("response, data");

        // return false;

        setTimeout(() => {
          history.push("/blast/device/list/admin", { replace: true });
        }, 400);
      })
      .catch((err) => {
        Swal.fire("Login Gagal", err.error_message, "error");
      });
  };

  return (
    <Container
      fluid={true}
      className="p-0"
      style={{
        overflow: "hidden",
      }}
    >
      <Row>
        <Col xs="12">
          <Card
            className={classNames("signup-card", {
              show: activePage === "register",
            })}
          >
            <CardBody>
              <Signup onBack={() => setActivePage("login")} />
            </CardBody>
          </Card>
          <div className="login-card">
            <div>
              <div></div>
              <div
                className={classNames("login-main login-tab", {
                  show: activePage === "login",
                })}
              >
                <TabPane
                  className="fade show"
                  tabId={selected === "firebase" ? "firebase" : "jwt"}
                >
                  <Form className="theme-form" onSubmit={e => {
                    e.preventDefault()
                     loginWithJwt(email, password);
                  }}>
                    <h4>{selected === "firebase" ? "Masuk" : "Masuk"}</h4>
                    <p>{"Masukkan email & password untuk melanjutkan"}</p>
                    <FormGroup>
                      <Label className="col-form-label">{EmailAddress}</Label>
                      <Input
                        className="form-control"
                        type="email"
                        required=""
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={email}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label className="col-form-label">{Password}</Label>
                      <InputGroup className="input-password">
                        <Input
                          className="form-control"
                          type={togglePassword ? "text" : "password"}
                          onChange={(e) => setPassword(e.target.value)}
                          defaultValue={password}
                          required=""
                        />

                        <InputGroupAddon
                          addonType="prepend"
                          onClick={() => setTogglePassword(!togglePassword)}
                        >
                          <i
                            className={classNames({
                              "ri-eye-line": togglePassword,
                              "ri-eye-off-line": !togglePassword,
                              "text-dark": !togglePassword,
                            })}
                          ></i>
                        </InputGroupAddon>
                      </InputGroup>
                      {/* <div
                        className="show-hide"
                        onClick={() => setTogglePassword(!togglePassword)}
                      >
                        <span className={togglePassword ? "" : "show"}></span>
                      </div> */}
                    </FormGroup>
                    <div className="form-group mb-0">
                      {selected === "firebase" ? (
                        <Button
                          color="primary"
                          className="btn-block"
                          disabled={loading ? loading : loading}
                          onClick={(e) => loginAuth(e)}
                        >
                          {loading ? "LOADING..." : SignIn}
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block mt-4"
                          // onClick={() => loginWithJwt(email, password)}
                        >
                          Login
                        </Button>
                      )}
                    </div>

                    <div className="mt-5 d-flex flex-column justify-content-center">
                      <p className="text-muted text-center mb-0 pb-0">
                        Belum punya akun ?
                      </p>
                      <Button
                        size="sm"
                        link
                        className="text-primary"
                        style={{
                          border: "none",
                        }}
                        color="light"
                        onClick={() => setActivePage("register")}
                      >
                        Daftar
                      </Button>
                    </div>
                    <h6 className="text-muted mt-4 or d-none">
                      {"Or Sign in with"}
                    </h6>
                    <div className="social mt-4 d-none">
                      <div className="btn-showcase">
                        <Button color="light" onClick={facebookAuth}>
                          <Facebook className="txt-fb" />
                        </Button>
                        <Button color="light" onClick={googleAuth}>
                          <i className="fa fa-google txt-googleplus"></i>
                        </Button>
                        <Button color="light" onClick={githubAuth}>
                          <GitHub />
                        </Button>
                      </div>
                    </div>
                    <p className="mt-4 mb-0 d-none">
                      {"Don't have account?"}
                      <a className="ml-2">{CreateAccount}</a>
                    </p>
                  </Form>
                </TabPane>
                {/* <TabPane  className="fade show" tabId="auth0">
                    <div className="auth-content">
                        <img src={require("../assets/images/auth-img.svg")} alt="" />
                        <h4>{"Welcome to login with Auth0"}</h4>
                        <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                        <Button color="info" onClick={loginWithRedirect}>{AUTH0}</Button> 
                    </div>
                  </TabPane> */}
                {/* </TabContent> */}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Logins);
