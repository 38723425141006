import React, { Component } from "react";
import Swal from "sweetalert2";
import { get, post } from "../../../utils/API";
import GenericAdd from "./GenericAdd";

class GenericAddController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      fields: [],
      isReady: false,
      form: {},
      lookupData: [],

      submit: false,
    };

    // this.lookup = this.lookup.bind(this);
  }

  componentDidMount() {
    this.getConfig();
  }

  getConfig() {
    const { model } = this.props.match.params;
    const config = require(`../../../models/${model}.js`).default;

    let fields = [];
    for (const field of config.field) {
      if (field.add == false) continue;
      // if (["lookup"].includes(field.type)) continue;
      fields.push(field);
    }
    this.setState({
      model: config,
      fields: fields,
      isReady: true,
    });
  }

  onChangeInput(key, value) {
    this.setState((prev) => ({
      form: {
        ...prev.form,
        [key]: value,
      },
    }));
  }

  lookup(inputValue, name) {
    return new Promise(async (resolve) => {
      get("whatsapp", { src: inputValue }).then((result) => {
        let response = [];
        for (const data of result.data) {
          response.push({
            label: `${data.name} (${data.phone})`,
            value: data.id,
          });
        }
        resolve(response);
      });
    });
  }
  lookupTest(inputValue, field) {
    return new Promise(async (resolve) => {
      get(field.lookupEndpoint, { src: inputValue }).then((result) => {
        let response = [];
        for (const data of result.data) {
          var displayFormat = field.relation_display_format;
          for (const display of field.relation_display) {
            displayFormat = displayFormat.replace(
              new RegExp(`\\%${display}%`, "gm"),
              data[display]
            );
          }
          response.push({
            label: displayFormat,
            // label: (
            //   <div>
            //     <img src={data.photo} width={32} height={32} />
            //   </div>
            // ),
            value: data.id,
          });
        }
        resolve(response);
        resolve([{ label: " test", value: 1 }]);
      });
    });
  }

  onSubmit() {
    this.setState({ submit: true });
    post(this.state.model.addEndpoint, this.state.form)
      .then(() => {
        Swal.fire({
          text: `Agen berhasil ditambahkan`,
          title: "Berhasil",
          icon: "success",
        });

        this.setState({ submit: false });
      })
      .catch((err) => {
        Swal.fire({
          text: err.error_message,
          title: "Gagal",
          icon: "error",
        });

        this.setState({ submit: false });
      });
  }

  onBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <GenericAdd
        state={this.state}
        onChangeInput={this.onChangeInput.bind(this)}
        onSubmit={this.onSubmit.bind(this)}
        lookup={this.lookup.bind(this)}
        lookupTest={this.lookupTest.bind(this)}
        onBack={this.onBack.bind(this)}
      />
    );
  }
}
export default GenericAddController;
