import React, { Fragment } from "react";
import Breadcrumb from "../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  CardBody,
} from "reactstrap";
import {
  BasicTable,
  InverseTable,
  InverseTablePrimaryBackground,
  HoverableRows,
  ContextualClasses,
  TextBackgroundUtilities,
  TableHeadOptions,
  StripedRow,
  StripedRowInverseTable,
  Caption,
  ResponsiveTables,
  BreckpointSpecific,
} from "../../constant";

const Development = () => {
  return (
    <Fragment>
      <Breadcrumb parent="" title="" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Dalam Pengembangan</h5>
              </CardHeader>
              <CardBody>
                <p>Sedang dalam tahap pengembangan</p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Development;
