import React, { Component } from "react";
import Swal from "sweetalert2";
import { del, get } from "../../utils/API";
import ContactList from "./ContactList";
import { toast } from "react-toastify";
import { connect } from "react-redux";

class ContactListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      active: {},

      totalRecord: null,

      filter: {
        limit: 10,
        offset: 0,
      },
    };

    this.tableRef = React.createRef();
  }
  componentDidMount() {
    this.getData();
  }

  getData(toTop = false) {
    get("contacts", this.state.filter).then((result) => {
      this.setState({ data: result.data, totalRecord: result.record }, () => {
        if (toTop) {
          this.tableRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
        this.props.setLoading(false);
      });
    });
  }

  setRef(key, value) {
    if (value) {
      this[key].current = value;
    }
  }

  onAdd() {
    this.props.history.push(`/app/contact/add/admin`);
  }

  onEdit(item) {
    this.props.history.push(`/app/contact/edit/${item.id}/admin`);
  }

  onDelete(item) {
    Swal.fire({
      title: "Hapus Data?",
      icon: "question",
      text: `Anda akan menghapus kontak ${item.whatsapp_name}, apakah anda yakin?`,
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Batal",
      confirmButtonText: "Ya, Hapus",
      confirmButtonColor: "red",
    }).then((action) => {
      if (action.isConfirmed) {
        del(`contacts/${item.id}`)
          .then(() => {
            toast.success("Data berhasil dihapus");
            this.setState(
              {
                active: {},
              },
              () => this.getData()
            );
          })
          .catch((err) => {
            toast.error(err.error_message || "Data berhasil dihapus");
          });
      }
    });
  }

  toggleActive(item) {
    this.setState({
      active: item ? item : {},
    });
  }

  onChangePage(page) {
    this.props.setLoading(true);
    this.setState(
      (prev) => ({
        filter: {
          ...prev.filter,
          offset: 0,
          limit: page,
        },
      }),
      () => {
        this.getData(true);
      }
    );
  }

  render() {
    return (
      <ContactList
        state={this.state}
        toggleActive={this.toggleActive.bind(this)}
        onAdd={this.onAdd.bind(this)}
        onDelete={this.onDelete.bind(this)}
        onEdit={this.onEdit.bind(this)}
        onChangePage={this.onChangePage.bind(this)}
        setRef={this.setRef.bind(this)}
        tableRef={this.tableRef}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  display: state.display,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (visible, message) =>
      dispatch({
        type: "SET_LOADING",
        payload: { visible: visible, message: message },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactListController);
