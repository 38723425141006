// dashbaord
import Default from "../components/dashboard/default";
import GenericListController from "../pages/generic/GenericList/GenericListController";
import AgentListController from "../pages/setting/AgentList/AgentListController";
import GenericAddController from "../pages/generic/GenericAdd/GenericAddController";
import GenericEditController from "../pages/generic/GenericEdit/GenericEditController";
import GenericViewController from "../pages/generic/GenericView/GenericViewController";
import DeviceListController from "../pages/DeviceList/DeviceListController";
import ChatListController from "../pages/ChatList/ChatListController";
import GenericAddControllerTest from "../pages/generic/GenericAdd/GenericAddController.test";
import PermissionController from "../pages/setting/Permission/PermissionController";
import ContactListController from "../pages/ContactList/ContactListController";
import Development from "../components/sample/Development";
import BroadcastCreateController from "../pages/BroadcastCreate/BroadcastCreateController";
import BroadcastListController from "../pages/BroadcastList/BroadcastListController";
import BroadcastViewController from "../pages/BroadcastView/BroadcastViewController";
import MyProfileController from "../pages/MyProfile/MyProfileController";
import BroadcastEditController from "../pages/BroadcastEdit/BroadcastEditController";
import MessageListController from "../pages/MessageList/MessageListController";
import DashboardController from "../pages/Dashboard/DashboardController";
import ConfigController from "../pages/Config/ConfigController";
import Error404 from "../pages/errors/error404";

export const routes = [
  { path: "/blast/contact/list/:layout", Component: ContactListController },
  { path: "/blast/broadcast/list/:layout", Component: BroadcastListController },
  {
    path: "/blast/broadcast/edit/:id/:layout",
    Component: BroadcastEditController,
  },
  {
    path: "/blast/broadcast/view/:id/:layout",
    Component: BroadcastViewController,
  },

  {
    path: "/blast/message/list/:layout",
    Component: MessageListController,
  },

  {
    path: "/dashboard/default-demo/:layout",
    Component: Default,
  },
  { path: "/app/:model/list/:layout", Component: GenericListController },
  { path: "/app/:model/add/:layout", Component: GenericAddController },
  { path: "/app/:model/edit/:id/:layout", Component: GenericEditController },
  { path: "/app/:model/view/:id/:layout", Component: GenericViewController },
  {
    path: "/setting/permission/:id/:layout",
    Component: PermissionController,
  },
  {
    path: "/blast/broadcast/add/:layout",
    Component: BroadcastCreateController,
  },

  {
    path: "/dashboard/default/:layout",
    Component: DashboardController,
  },
  { path: "/blast/device/list/:layout", Component: DeviceListController },
  { path: "/blast/chat/list/:id/:layout", Component: ChatListController },
  { path: "/blast/config/:layout", Component: ConfigController },
  { path: "/development/:layout/", Component: Development },
 
];
