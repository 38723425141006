export function hasPermission(type, permission) {
  let permissions = JSON.parse(localStorage.getItem("permissions"));
  if (!permissions) return false
  try {
    return permissions[permission][type];
  } catch {
    return false;
  }
}

export function hasOneOfPermission(type, permission) {
  if (!permission) return false;
  let permissions = JSON.parse(localStorage.getItem("permissions"));

  if (!permissions) return false

  let permissionArray = permission.split("|");
  // console.log("arr" , permissionArray)

  if (permissionArray.length == 0) return false;
  for (const perm of permissionArray) {
    // console.log(permissions[perm]);
    if (permissions[perm] && permissions[perm][type]) {
      return true;
    }
    // console.log(perm)
    // if (permissions[perm][type]) return true
    // console.log (permissions[perm][type])
  }
  // console.log('last line ex')
  return false;
}

const Permission = {
  hasPermission,
};
export default Permission;
