import React, { Component } from "react";
import { get } from "../../utils/API";
import MessageList from "./MessageList";

class MessageListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      refresh: true,
      data: [],
      isReady: false,
      filter: {
        limit: 10,
        offset: 0,
      },
      totalRecord: null,
    };
  }

  componentDidMount() {
    this.getData();
    // alert("fired");
  }

  getData() {
    get("messages", this.state.filter).then((response) => {
      //   console.log("resp", response);
      this.setState((prev) => ({
        data: response.data,
        totalRecord: response.record,
      }));
    });
  }

  onPageChange(filter = {}) {
    this.setState(
      (prev) => ({
        filter: {
          ...prev.filter,
          ...filter,
        },
      }),
      () => this.getData()
    );
  }

  onDelete(data) {}

  render() {
    return (
      <MessageList
        state={this.state}
        onDelete={this.onDelete.bind(this)}
        onPageChange={this.onPageChange.bind(this)}
      />
    );
  }
}

export default MessageListController;
