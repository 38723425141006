import React, { Component } from "react";
import { get } from "../../../utils/API";
import GenericView from "./GenericView";

class GenericViewController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {},
      model: {},
      fields: [],
      isReady: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const { id, model } = this.props.match.params;

    const modelSrc = require(`../../../models/${model}.js`).default;
    get(modelSrc.viewEndpoint.replace(":id", id)).then((response) => {
      // console.log(response);

      if (response) {
        this.setState({ data: response, model: modelSrc }, () =>
          this.getConfig()
        );
      } else {
        this.setState({ isReady: true });
      }
    });
  }

  getConfig() {
    let fields = [];
    for (const field of this.state.model.fields) {
      if (field.view.allow !== true) continue;

      var displayFormat = field.view.displayFormat;
      for (const displayIdx of field.view.display.keys()) {
        displayFormat = displayFormat.replace(
          new RegExp(`\\%${displayIdx}%`, "gm"),
          this.state.data[field.view.display[displayIdx]]
        );
      }

      // console.log("disp", displayFormat);

      if ([null, "null"].includes(displayFormat)) {
        displayFormat = "-";
      }

      let model = {
        label: field.label,
        value: displayFormat,
      };
      fields.push(model);

      // fields.push({
      //   ...field.view,
      //   name: field.name,
      //   label: field.label,
      // });

      //   if (field.type === "lookup") {
      //     var displayFormat = field.relation_display_format;
      //     for (const defaultField of Object.keys(this.state.initialData)) {
      //       console.log("test", defaultField);
      //       displayFormat = displayFormat.replace(
      //         new RegExp(`\\%${defaultField}%`, "gm"),
      //         this.state.initialData[defaultField]
      //       );
      //     }

      //     let stateName = `selected_${field.name}`;

      //     this.setState({
      //       [stateName]: {
      //         label: displayFormat,
      //         value: this.state.initialData[field.name],
      //       },
      //     });
      //   }
    }
    this.setState({
      fields: fields,
      isReady: true,
    });
  }

  processData() {}

  onBack() {
    this.props.history.goBack();
  }

  render() {
    if (!this.state.isReady) return null;
    return <GenericView state={this.state} onBack={this.onBack.bind(this)} />;
  }
}

export default GenericViewController;
