import moment from "moment";
import React, { Fragment, memo } from "react";
import { useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Badge,
  Form,
  FormGroup,
  InputGroup,
  Label,
  Tooltip,
  Input,
  CardBody,
} from "reactstrap";
import FormPagination from "../../components/forms/FormPagination";
import Breadcrumb from "../../layout/breadcrumb";
import {
  toIDR,
  avatarHelper,
  formatPhone,
  formatChat,
  filterMessage,
} from "../../utils/Formatter";
import "./broadcast-view.scss";

function BroadcastView({
  state,
  onAdd,
  onView,
  onEdit,
  onDelete,
  onCustomAction,
  onChangePage,
  onSearch,
  onBack,
}) {
  const onChangePagination = useCallback((limit, offset) => {
    onChangePage(limit, offset);
  });
  return (
    <Fragment>
      <Breadcrumb parent="Broadcast" title={`Detail Broadcast`} />
      <Container fluid={true} className="broadcast-view-page">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex flex-row justify-content-between align-items-center">
                <h5>Detail Broadcast</h5>
                <Button color="light" onClick={() => onBack()}>
                  Kembali
                </Button>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Pesan</Label>
                      <p className="text-muted">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: filterMessage(state.data.message),
                          }}
                        />
                      </p>
                    </FormGroup>

                    <FormGroup>
                      <Label>Dijadwalkan</Label>
                      <p className="text-muted">
                        {state.data.schedule_at
                          ? moment(state.data.schedule_at).format(
                              "D MMM YYYY HH:mm:ss"
                            )
                          : "-"}
                      </p>
                    </FormGroup>
                  </Col>

                  <Col md={6} className="contact-list pl-5">
                    <h6 className="text-dark pb-4">
                      <strong>Penerima</strong>{" "}
                      {`(${state.data.total_recipient} kontak)`}
                    </h6>
                    {Array.isArray(state.data.detail) &&
                      state.data.detail.map((contact, i) => (
                        <div className="contact-wrapper d-flex flex-row py-3">
                          <div className="avatar d-flex justify-content-center align-items-center">
                            <img
                              className="img-40 rounded-circle"
                              src={
                                contact.photo
                                  ? contact.photo
                                  : require("../../assets/images/logo/default-avatar.png")
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = require("../../assets/images/logo/default-avatar.png");
                              }}
                            />
                          </div>

                          <div className="content d-flex flex-column justify-content-center pl-2">
                            <p className=" h6 text-dark mb-0">{contact.name}</p>
                            <p className="text-muted">
                              {formatPhone(contact.phone)}
                            </p>
                          </div>
                        </div>
                      ))}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default memo(BroadcastView);
