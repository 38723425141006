import axios from "axios";

// const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = "https://api.chatku.xyz";
const BASE_URL = "https://apiv1.chatku.xyz";
function handleError(error) {
  try {
    if (error.status === 401) {
      localStorage.clear();
      return (window.location.href = "/login");
    }
    return error.data;
  } catch {
    return {
      error_message: "Request Timeout",
    };
  }
}

export function get(path, params = {}) {
  const token = localStorage.getItem("token");
  let endPoint = BASE_URL;

  if (path.charAt(0) == "/") {
    endPoint += path;
  } else {
    endPoint += `/${path}`;
  }

  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${BASE_URL}/${path}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      params: params,
    })
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        let error = handleError(err.response);
        return reject(error);
      });
  });
}

export function post(path, body = {}) {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${BASE_URL}/${path}`,
      headers: {
        "Content-Type": "application/json",

        Authorization: token,
      },
      data: body,
    })
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        let error = handleError(err.response);
        return reject(error);
      });
  });
}

export function del(path, body = {}) {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url: `${BASE_URL}/${path}`,
      headers: {
        "Content-Type": "application/json",

        Authorization: token,
      },
      data: body,
    })
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        let error = handleError(err.response);
        return reject(error);
      });
  });
}

export function edit(path, body = {}) {
  const token = localStorage.getItem("token");
  return new Promise((resolve, reject) => {
    axios({
      method: "PATCH",
      url: `${BASE_URL}/${path}`,
      headers: {
        "Content-Type": "application/json",

        Authorization: token,
      },
      data: body,
    })
      .then((res) => {
        return resolve(res.data.data);
      })
      .catch((err) => {
        let error = handleError(err.response);
        return reject(error);
      });
  });
}
