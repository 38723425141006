import { FixedScaleAxis } from "chartist";
import classNames from "classnames";
import React, { useState, useEffect, memo, useCallback } from "react";
import QRCode from "react-qr-code";
import { MoonLoader } from "react-spinners";

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Badge,
  Button,
} from "reactstrap";

function ConnectWhatsapp({ status, onReload }) {
  const [timeout, setTimeoutQR] = useState(false);

  useEffect(() => {
    let timeoutID = "";
    if (status.loading === false && status.qr !== "" && status.error == false) {
      timeoutID = window.setTimeout(() => {
        setTimeoutQR(true);
      }, 20000);
    }
    return () => {
      if (timeoutID) {
        window.clearTimeout(timeoutID);
      }
    };
  }, [status]);

  const reloadQr = useCallback(() => {
    if (onReload instanceof Function) {
      onReload();
      setTimeoutQR(false);
    }
  });
  return (
    <div style={{ paddingTop: "4em" }}>
      <Card className="connect-whatsapp">
        <CardBody>
          <Row>
            <Col lg={6} className="py-3">
              <p className="h5 mb-5 text-secondary">
                Untuk Menggunakan Whatsapp di Komputer anda:
              </p>

              <ol className="flex-column pl-3">
                <li className="h6 my-2 text-secondary">
                  Buka Whatsapp pada Telepon Anda
                </li>
                <li className="h6 my-2 text-secondary">
                  Ketuk <strong>Menu</strong>{" "}
                  <Badge color="light" className="text-center">
                    <i className="ri-more-2-fill text-secondary"></i>
                  </Badge>{" "}
                  atau <strong>Setelan</strong>{" "}
                  <Badge color="light">
                    <i className="ri-settings-4-fill text-secondary"></i>
                  </Badge>{" "}
                  dan pilih Perangkat Tertaut
                </li>
                <li className="h6 my-2 text-secondary">
                  Arahkan telepon Anda ke layar ini untuk menghubungkan
                </li>
              </ol>
            </Col>

            <Col
              lg={6}
              className="d-flex flex-column justify-content-center align-items-center"
            >
              <div
                className="p-relative"
                style={{
                  width: window.innerWidth * 0.2,
                  height: window.innerWidth * 0.2,
                }}
              >
                <div
                  className={classNames("qr-error", {
                    show: timeout,
                  })}
                >
                  <Button color="success" onClick={reloadQr}>
                    <i className="ri-restart-line h1"></i>
                    <span>Klik untuk Memuat Ulang QR Code</span>
                  </Button>
                </div>
                <div
                  className={classNames("qr-inner", {
                    loading: status.loading,
                  })}
                >
                  <MoonLoader color="grey" loading width={100} height={100} />
                </div>
                {/* <QRCode
                value="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum."
                size={window.innerWidth * 0.2}
              /> */}

                {
                  <img
                    src={status.qrCode}
                    style={{
                      width: window.innerWidth * 0.2,
                      height: window.innerWidth * 0.2,
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
}

const areEqual = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.status) === JSON.stringify(nextProps.status);
};

export default memo(ConnectWhatsapp, areEqual);
