import React, { memo, Fragment, useState, useEffect } from "react";
import {
  formatChat,
  formatChatDate,
  formatDateSeparator,
} from "../../utils/Formatter";
import { ReactTinyLink } from "react-tiny-link";
import cheerio from "cheerio";
import { Card, Row, Col } from "reactstrap";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import moment from "moment";

function RenderChat({ text, item, prevDate }) {
  const [link, setLink] = useState(false);
  const [linkInfo, setLinkInfo] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    var findLinks =
      /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/.exec(
        item.message
      );
    if (Array.isArray(findLinks) && findLinks.length > 0) {
      fetch(findLinks[0])
        .then((response) => {
          return response.text();
        })
        .then((html) => {
          const $ = cheerio.load(html);
          const title =
            $('meta[property="og:title"]').attr("content") ||
            $("title").text() ||
            $('meta[name="title"]').attr("content");
          const description =
            $('meta[property="og:description"]').attr("content") ||
            $('meta[name="description"]').attr("content");
          const site_name = $('meta[property="og:site_name"]').attr("content");
          const url = $('meta[property="og:url"]').attr("content");

          const icon =
            $('link[rel="icon"]').attr("href") ||
            $('link[rel="shortcut icon"]').attr("href");
          const image =
            $('meta[property="og:image"]').attr("content") ||
            $('meta[property="og:image:url"]').attr("content");

          let _linkInfo = {
            title,
            description,
            site_name,
            icon,
            image,
            hostname: new URL(url).hostname,
            url,
          };

          // console.log("link", _linkInfo);

          setLink(findLinks[0]);
          setLinkInfo(_linkInfo);
        })
        .catch((err) => {});
    }
    // return () => {
    //   cleanup;
    // };
  }, []);

  const _renderMessage = () => {
    return (
      <Fragment>
        {link && (
          <a
            href={linkInfo.url}
            target="_blank"
            className="canonical-card d-flex flex-row"
          >
            <div className="img-wrapper">
              <img src={linkInfo.image} />
            </div>
            <div className="link-info py-2">
              <p className="mb-1 link-title text-dark">{linkInfo.title}</p>
              <p className="link-description text-muted mb-1 small">
                {linkInfo.description}
              </p>
              <p className="link-url text-dark small mb-0">
                {linkInfo.hostname}
              </p>
            </div>
          </a>
        )}

        <div
          className="px-3 pt-1 pb-0"
          dangerouslySetInnerHTML={{
            __html: formatChat(item.message),
          }}
        ></div>
      </Fragment>
    );
  };

  const openImage = () => {
    dispatch({
      type: "SHOW_LIGHTBOX",
      payload: {
        smallSource: item.attachment,
        largeSource: item.attachment,
        alt: item.message,
      },
    });
  };

  if (item.type_id == 1 && !item.message) return null;

  return (
    <Fragment>
      {prevDate !== item.date && (
        <div className="d-flex flex-row justify-content-center date-separator">
          <p>{formatDateSeparator(item.date)}</p>
        </div>
      )}
      <div
        className={classNames(`message my-message p-0`, {
          "float-right": item.flag == "O",
        })}
      >
        {item.type_id == "1" && _renderMessage()}
        {item.type_id == "2" && (
          <Fragment>
            <div
              className={classNames("chat-image mx-1", {
                "mt-3": !item.message,
                "mt-1": item.message,
              })}
            >
              <img
                className="cursor-pointer"
                src={item.attachment}
                style={{ width: "100%" }}
                onClick={openImage}
              />
            </div>

            {item.message ? _renderMessage() : null}
          </Fragment>
        )}

        <div className="message-data text-right mt-0 mb-0 pb-0">
          <span className="message-data-time">
            {formatChatDate(`${item.date} ${item.time}`, "YYYY-MM-DD HH:mm:ss")}
          </span>
        </div>
      </div>
    </Fragment>
  );
}

export default memo(RenderChat);
