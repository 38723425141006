import moment from "moment";

export function avatarHelper(name) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  if (!name) return "?";

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();

  if (!initials) {
    return name.substr(0, name.length == 1 ? 1 : 2);
  }

  return initials;
}

export function formatChat(text) {
  let processed = text
    .replace(/\*{1,2}(.*?)\*{1,2}/g, "<strong>$1</strong>")
    .replace(/\_{1,2}(.*?)\_{1,2}/g, "<i>$1</i>")
    .replace(/\n/g, " <br />")
    .replace(/(https?:\/\/)([^ ]+)/g, '<a target="_blank" href="$&">$2</a>');

  return processed;
}

export function formatPhone(phone) {
  var isPhone = /^\d+$/.test(phone);
  if (!isPhone) {
    return phone;
  }

  if (phone.charAt(0) == "6") {
    return (
      "+" +
      phone.substring(0, 2) +
      " " +
      phone.substring(2, 5) +
      "-" +
      phone
        .substring(5, phone.length)
        .match(/.{1,4}/g)
        .join("-")
    );
  }

  return (
    "+" +
    phone.substring(0, 2) +
    " " +
    phone
      .substring(2, phone.length)
      .match(/.{1,4}/g)
      .join("-")
  );
}

export function formatDateSeparator(date, format = "YYYY-MM-DD") {
  const dateNow = moment();
  const dateFuture = moment(date, format);

  const diffDay = dateNow.diff(dateFuture, "day");

  if (diffDay == 0) {
    return "Hari Ini";
  } else if (diffDay == 1) {
    return "Kemarin";
  } else if (diffDay <= 7) {
    return dateFuture.format("dddd");
  } else {
    return dateFuture.format("D MMM YYYY");
  }
  return diffDay;
}

export function formatDate(date, format = "DD/MM/YYYY HH:mm") {
  const dateNow = moment();
  const dateFuture = moment(date, format);
  const diffDay = dateNow.diff(dateFuture, "day");

  const diffHour = dateNow.diff(dateFuture, "hours");
  const diffMinute = dateNow.diff(dateFuture, "minutes");

  if (diffDay > 0) return moment().format("DD MMM YYYY HH:mm");

  if (diffHour > 0) return `${diffHour} jam yang lalu`;

  if (isNaN(diffMinute)) return date;

  return `${diffMinute} menit yang lalu`;
}

export function filterMessage(text) {
  if (!text) return text;
  var chatMessage = text
    .replace(/\*{1,2}(.*?)\*{1,2}/g, "<strong>$1</strong>")
    .replace(/\_{1,2}(.*?)\_{1,2}/g, "<i>$1</i>")
    .replace(/\n/g, " <br />")
    .replace(/(https?:\/\/)([^ ]+)/g, '<a target="_blank" href="$&">$2</a>');

  return chatMessage;
}

export function formatChatDate(date, format = "DD/MM/YYYY HH:mm:ss") {
  const dateNow = moment();
  const dateFuture = moment(date, format);
  const diffDay = dateNow.diff(dateFuture, "day");

  if (diffDay > 0) return moment().format("DD MMM HH:mm");

  return dateFuture.format("HH:mm");
}

export function formatChatList(date, format = "DD/MM/YYYY HH:mm:ss") {
  const dateFuture = moment(date, format)
  const diffDay = moment().diff(dateFuture, "day")

  if ( diffDay == 1) return "Kemarin"
  if (diffDay > 1) return dateFuture.format("D MMM");

  return dateFuture.format("HH:mm")

}

export function toIDR(number) {
  if (!number) {
    return number;
  }
  var reverse = number.toString().split("").reverse().join(""),
    ribuan = reverse.match(/\d{1,3}/g);
  ribuan = ribuan.join(".").split("").reverse().join("");

  return "IDR " + ribuan;
}

const Formatter = {
  formatPhone,
  formatDate,
};

export default Formatter;
