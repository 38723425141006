const model = {
  name: "broadcast",
  label: "Broadcast",
  addEndpoint: "broadcast",
  listEndpoint: "broadcast",
  editEndpoint: "broadcast",
  viewEndpoint: "broadcast/:id",
  addPath: "/blast/broadcast/add/admin",
  add: true,
  list: true,
  edit: true,
  view: true,
  searchable: false,

  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: { allow: false },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },
    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: false,
        display: ["username", "phone"],
        displayFormat: "%0%",
        viewDisplay: "username",
        wrapperClass: "d-flex flex-column",
        class: ["mb-0 h6 font-weight-bold", ""],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["username"],
        displayFormat: "%0%",
        viewDisplay: "username",
      },

      view: {
        allow: true,
        display: ["username"],
        displayFormat: "%0%",
        viewDisplay: "username",
      },

      delete: {
        allow: true,
      },
    },
    {
      name: "message",
      label: "Pesan",
      filter: false,
      list: {
        allow: false,
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "username",
        wrapperClass: "d-flex flex-column",
        class: ["mb-0 h6 font-weight-bold", ""],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      view: {
        allow: true,
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      delete: {
        allow: true,
      },
    },
    {
      name: "schedule_date",
      label: "Tanggal",
      filter: false,
      list: {
        allow: false,
        display: ["schedule_date"],
        displayFormat: "%0%",
        viewDisplay: "username",
        wrapperClass: "d-flex flex-column",
        class: ["mb-0 h6 font-weight-bold", ""],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "date",
        dateFormat: "YYYY-MM-DD",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "date",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      view: {
        allow: true,
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      delete: {
        allow: true,
      },
    },
    {
      name: "schedule_time",
      label: "Jam",
      filter: false,
      list: {
        allow: false,
        display: ["schedule_time"],
        displayFormat: "%0%",
        viewDisplay: "username",
        wrapperClass: "d-flex flex-column",
        class: ["mb-0 h6 font-weight-bold", ""],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "time",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "date",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      view: {
        allow: true,
        display: ["message"],
        displayFormat: "%0%",
        viewDisplay: "message",
      },

      delete: {
        allow: true,
      },
    },

    {
      name: "group_contact",
      label: "Grup Kontak",
      filter: false,
      list: {
        allow: false,
        display: ["group_contact"],
        displayFormat: "%0%",
        viewDisplay: "group_contact",
        class: ["btn btn-primary"],
        type: "button",
        props: {
          size: "sm",
          color: "primary",
        },
        pathname: null,
        // viewValues: [
        //   {
        //     label: "Aktif",
        //     value: 1,
        //     class: "badge badge-success",
        //   },
        //   {
        //     label: "Tidak Aktif",
        //     value: 0,

        //     class: "badge badge-secondary",
        //   },
        // ],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name"],
        displayFormat: "%0%",
        lookupEndpoint: "group/contacts",
        maxLength: null,
        required: true,
        multiple: true,
        renderBefore: "",
      },

      edit: {
        allow: true,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
        validate: null,
        required: true,
        viewValues: [
          {
            label: "Aktif",
            value: 1,
          },
          {
            label: "Tidak Aktif",
            value: 0,
          },
        ],
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["active"],
        type: "select",
        viewDisplay: "active",
        displayFormat: "%0%",
      },
    },

    {
      name: "contact",
      label: "Kontak",
      filter: false,
      list: {
        allow: false,
        display: ["contact"],
        displayFormat: "%0%",
        viewDisplay: "contact",
        class: ["btn btn-primary"],
        type: "button",
        props: {
          size: "sm",
          color: "primary",
        },
        pathname: null,
        // viewValues: [
        //   {
        //     label: "Aktif",
        //     value: 1,
        //     class: "badge badge-success",
        //   },
        //   {
        //     label: "Tidak Aktif",
        //     value: 0,

        //     class: "badge badge-secondary",
        //   },
        // ],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name"],
        displayFormat: "%0%",
        lookupEndpoint: "contacts",
        maxLength: null,
        required: true,
        multiple: true,
        renderBefore: "",
      },

      edit: {
        allow: true,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
        validate: null,
        required: true,
        viewValues: [
          {
            label: "Aktif",
            value: 1,
          },
          {
            label: "Tidak Aktif",
            value: 0,
          },
        ],
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["active"],
        type: "select",
        viewDisplay: "active",
        displayFormat: "%0%",
      },
    },
  ],
};

export default model;
