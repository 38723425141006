import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Button,
  Table,
} from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";

function AgentList({ state, navigateToAdd, onDelete, onEdit, onView }) {
  return (
    <Fragment>
      <Breadcrumb parent="Setting" title="List Agent" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex flex-row justify-content-between">
                <h5>{"List Agent"}</h5>
                <Button color="primary" onClick={() => navigateToAdd()}>
                  Tambah Agen
                </Button>
              </CardHeader>
              <CardBody>
                {/* <DataTable
                  data={state.data}
                  columns={state.fields.concat([
                    {
                      name: "#",
                      cell: (row, index) => {
                        console.log("row", row);
                        return (
                          <div className="d-flex flex-row justify-content-around">
                            <Button
                              onClick={() => onView(row)}
                              color="primary"
                              size="xs"
                            >
                              V
                            </Button>
                            <Button
                              onClick={() => onEdit(row)}
                              color="warning"
                              size="xs"
                            >
                              E
                            </Button>
                            <Button
                              color="danger"
                              size="xs"
                              onClick={() => onDelete(row)}
                            >
                              D
                            </Button>
                          </div>
                        );
                      },
                    },
                  ])}
                  striped={true}
                  center={true}
                  selectableRows
                  persistTableHead
                  responsive
                /> */}

                <div className="table-responsive">
                  <Table>
                    <thead>
                      <tr>
                        {state.fields.map((field, i) => (
                          <th scope="col" key={`head-${field.name}`}>
                            {field.name}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {state.data.map((data, i) => (
                        <tr key={`row-${i}`}>
                          {state.fields.map((config, i) => (
                            <td key={`cell-${i}`}>{data[config.name]}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default AgentList;
