import React, { Fragment } from "react";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  FormFeedback,
  CustomInput,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumb from "../../layout/breadcrumb";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import moment from "moment";

function BroadcastCreate({
  state,
  onChangeInput,
  lookup,
  lookupTest,
  onSubmit,
  onBack,
  setValue,
  lookupContact,
  lookupGroupContact,
}) {
  if (!state.isReady) return null;
  return (
    <Fragment>
      <Breadcrumb parent="Broadcast" title={`Broadcast`} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Card>
                <CardHeader className="d-flex flex-row justify-content-between">
                  <h5>Buat Siaran</h5>

                  <Button color="light" onClick={() => onBack()}>
                    Kembali
                  </Button>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="message" className="required">
                          Pesan
                        </Label>
                        <Input
                          id="message"
                          name="message"
                          placeholder="Masukkan pesan..."
                          type="textarea"
                          rows={5}
                          required
                          onChange={(e) =>
                            onChangeInput(e.target.name, e.target.value)
                          }
                        />
                      </FormGroup>

                      <FormGroup>
                        <CustomInput
                          onChange={(e) =>
                            setValue(
                              "schedule",
                              e.target.checked ? true : false
                            )
                          }
                          id={`schedule`}
                          name="schedule"
                          label="Jadwalkan"
                          type="switch"
                          checked={state.schedule}
                        />
                      </FormGroup>

                      {state.schedule && (
                        <Fragment>
                          <FormGroup>
                            <Label for="schedule_date">Tanggal</Label>
                            <DatePicker
                              selected={
                                state.form.schedule_date
                                  ? new Date(state.form.schedule_date)
                                  : false
                              }
                              required={false}
                              onChange={(date) => {
                                let dateValue =
                                  moment(date).format("YYYY-MM-DD");
                                onChangeInput("schedule_date", dateValue);
                              }}
                              inline
                            />
                          </FormGroup>

                          <FormGroup>
                            <Label for="schedule_time">Pesan</Label>
                            <Input
                              id="schedule_time"
                              name="schedule_time"
                              type="time"
                              onChange={(e) =>
                                onChangeInput(e.target.name, e.target.value)
                              }
                            />
                          </FormGroup>
                        </Fragment>
                      )}
                    </Col>

                    <Col md={6}>
                      <FormGroup>
                        <Label for="group_contact">Pilih Grup Kontak</Label>
                        <AsyncSelect
                          defaultOptions
                          isMulti={true}
                          placeholder="Cari Grup Kontak"
                          // loadOptions={lookup}
                          loadOptions={(event) => lookupGroupContact(event)}
                          onChange={(event) => {
                            let values = [];
                            for (const group of event) {
                              values.push(group.value);
                            }

                            onChangeInput("group_contact", values);
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="group_contact">Pilih Kontak</Label>
                        <AsyncSelect
                          defaultOptions
                          isMulti={true}
                          placeholder="Cari Kontak"
                          loadOptions={(event) => lookupContact(event)}
                          onChange={(event) => {
                            let values = [];
                            for (const group of event) {
                              values.push(group.value);
                            }

                            onChangeInput("contact", values);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter className="d-flex flex-row justify-content-end">
                  <Button
                    color="primary"
                    size="md"
                    type="submit"
                    disabled={state.submit}
                  >
                    Kirim
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default BroadcastCreate;
