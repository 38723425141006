import React, { Component } from "react";
import GenericList from "./GenericList";
import { del, get } from "../../../utils/API";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { toast } from "react-toastify";

class GenericListController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {},
      tableColumns: [],
      fields: [],
      isReady: false,
      data: [],
      loading: true,
      totalRecord: null,
      filter: {
        limit: 10,
        offset: 0,
        search: "",
      },
      notFound: null,
    };

    this.getData = this.getData.bind(this);
    this.getConfig = this.getConfig.bind(this);
    this.processResponse = this.processResponse.bind(this);
    this.timeoutUpdatePage = null;
  }

  componentDidMount() {
    this.getConfig();
  }

  getData() {
    get(this.state.model.listEndpoint, this.state.filter)
      .then((response) => {
        // console.log("data", response);

        this.setState(
          { data: response.data, totalRecord: response.record },
          () => this.processResponse(response.data)
        );
      })
      .catch((err) => {
        // Swal.fire("Gagal", err.error_message, "error");
      });
  }

  getConfig() {
    if (!this.props.match) return false;
    const model = this.props.match.params.model;

    if (!model) {
      this.setState({ notFound: true });
      return;
    }

    try {
      const modelSrc = require(`../../../models/${model}.js`).default;
      if (typeof modelSrc.list === "boolean" && modelSrc.list === false) {
        return false;
      }

      let fields = [];

      for (const field of modelSrc.fields) {
        if (field.list.allow !== true) continue;

        fields.push({
          ...field.list,
          name: field.name,
          label: field.label,
        });
      }

      window.scrollTo(0, 0);

      this.setState(
        {
          model: modelSrc,
          fields: fields,
          isReady: true,
          notFound: false,
        },
        () => this.getData()
      );
    } catch {
      alert("called");
      this.setState({ notFound: true });
    }
  }

  onSearch(search) {
    this.setState(
      (prev) => ({
        filter: {
          ...prev.filter,
          search: search,
        },
      }),
      () => this.getData()
    );
  }

  processResponse(fields) {
    for (const field of fields) {
      for (const model of this.state.fields) {
        //string
        var displayFormat = model.displayFormat;
        //array string

        var displays = model.display;

        for (const displayIndex of displays.keys()) {
          displayFormat = displayFormat.replace(
            new RegExp(`\\%${displayIndex}%`, "gm"),
            field[model.display[displayIndex]]
          );
        }

        // if (Array.isArray(model.viewValues)) {
        //   let viewDisplay = model.viewValues.find(
        //     (v) => v.value == field[model.viewDisplay]
        //   );
        //   field[model.viewDisplay] = viewDisplay.label;

        //   field.className = viewDisplay.class;
        // } else {
        //   field[model.viewDisplay] = displayFormat;

        //   field.className =
        //     Array.isArray(field.class) && field.class.length > 0
        //       ? field.class[0]
        //       : "";
        // }
      }
    }

    this.setState(
      {
        data: fields,
        loading: false,
      },
      () => {
        clearTimeout(this.timeoutUpdatePage);
        this.props.setLoading(false);
      }
    );
  }

  onView(item) {
    this.props.history.push({
      pathname: `/app/${this.state.model.name}/view/${item.id}/admin`,
    });
  }

  onEdit(item) {
    this.props.history.push(
      `/app/${this.state.model.name}/edit/${item.id}/admin`
    );
  }

  onDelete(item) {
    Swal.fire({
      title: "Apakah anda yakin",
      text: "Apakah anda yakin ingin menghapus?",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Batal",
      confirmButtonColor: "#ff0000",
      showConfirmButton: true,
    }).then((action) => {
      if (action.isConfirmed) {
        del(this.state.model.deleteEndpoint.replace(":id", item.id))
          .then(() => {
            // Swal.fire("Berhasil", "Item berhasil dihapus", "success");
            toast.success(`Data ${this.state.model.name} berhasil dihapus`);
            this.getData();
          })
          .catch((err) => {
            Swal.fire("Gagal", err.error_message, "error");
          });
      }
    });
  }

  onAdd() {
    if (this.state.model.addPath) {
      this.props.history.push(this.state.model.addPath);
    } else {
      this.props.history.push(`/app/${this.state.model.name}/add/admin`);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      let isFocused = this.props.location.pathname.split("/").includes("list");
      if (isFocused) {
        this.setState(
          {
            model: {},
            tableColumns: [],
            fields: [],
            isReady: true,
            loading: true,
          },
          () => {
            this.timeoutUpdatePage = setTimeout(() => {
              if (this.state.fields.length > 0) {
                clearTimeout(this.timeoutUpdatePage);
              }
            }, 400);
            this.getConfig();
          }
        );
      }
    }
  }

  onCustomAction(data, action) {
    let dataKey = action.pathname
      .split("/")
      .find((v) => v.charAt(0) == ":")
      .substr(1);
    let params = data[dataKey];
    if (!params) return false;
    this.props.history.push(
      `${action.pathname.replace(`:${dataKey}`, params)}`
    );
  }

  onChangePage(limit, offset) {
    if (
      limit == this.state.filter.limit &&
      offset == this.state.filter.offset
    ) {
      return false;
    }
    this.props.setLoading(true);
    this.setState(
      (prev) => ({
        filter: {
          ...prev.filter,
          limit: limit,
          offset: offset,
        },
      }),
      () => {
        this.getData();
      }
    );
  }

  render() {
    if (this.state.isReady === false) {
      return null;
    }

    // console.log("LOAIDNG", this.state.loading);

    return (
      <GenericList
        state={this.state}
        onView={this.onView.bind(this)}
        onEdit={this.onEdit.bind(this)}
        onDelete={this.onDelete.bind(this)}
        onAdd={this.onAdd.bind(this)}
        onCustomAction={this.onCustomAction.bind(this)}
        onChangePage={this.onChangePage.bind(this)}
        onSearch={this.onSearch.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  display: state.display,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (visible, message) =>
      dispatch({
        type: "SET_LOADING",
        payload: { visible: visible, message: message },
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericListController);
