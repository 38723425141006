const model = {
  name: "subscription",
  label: "Subscription",
  addEndpoint: "subscription_service",
  listEndpoint: "subscription_service",
  editEndpoint: "subscription_service",
  viewEndpoint: "subscription_service/:id",
  deleteEndpoint: "subscription_service/:id",
  add: true,
  edit: true,
  list: true,
  delete: true,
  view: true,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["name"],
        viewDisplay: "name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "name",
      },
    },

    {
      name: "code",
      label: "Kode",
      filter: false,
      list: {
        allow: true,
        display: ["code"],
        viewDisplay: "code",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["code"],
        displayFormat: "%0%",
        viewDisplay: "code",
      },
    },

    {
      name: "quota",
      label: "Kuota",
      filter: false,
      list: {
        allow: true,
        display: ["quota"],
        viewDisplay: "quota",
        displayFormat: "%0%",
        image: true,
        wrapperClass: "text-center",
      },
      add: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["quota"],
        displayFormat: "%0%",
        viewDisplay: "quota",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["quota"],
        displayFormat: "%0%",
        viewDisplay: "quota",
      },
    },
    {
      name: "agent",
      label: "Jumlah Agen",
      filter: false,
      list: {
        allow: true,
        display: ["agent"],
        viewDisplay: "agent",
        displayFormat: "%0%",
        image: true,
        wrapperClass: "text-center",
      },
      add: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["agent"],
        displayFormat: "%0%",
        viewDisplay: "agent",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["agent"],
        displayFormat: "%0%",
        viewDisplay: "agent",
      },
    },

    {
      name: "price",
      label: "Harga",
      filter: false,
      list: {
        allow: true,
        display: ["price"],
        viewDisplay: "price",
        displayFormat: "price",
        image: true,
        wrapperClass: "text-center",
      },
      add: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "integer",
        type: "number",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["price"],
        displayFormat: "%0%",
        viewDisplay: "price",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["price"],
        displayFormat: "%0%",
        viewDisplay: "price",
      },
    },

    {
      name: "crm",
      label: "CRM",
      filter: false,
      list: {
        allow: true,
        display: ["crm"],
        viewDisplay: "crm",
        displayFormat: "%0%",
        image: true,
        wrapperClass: "text-center",
        type: 'switch'
      },
      add: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
      },
      edit: {
        allow: true,
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        display: ["crm"],
        displayFormat: "%0%",
        viewDisplay: "crm",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["crm"],
        displayFormat: "%0%",
        viewDisplay: "crm",
      },
    },

    {
      name: "open_api",
      label: "Open API",
      filter: false,
      list: {
        allow: true,
        display: ["open_api"],
        viewDisplay: "open_api",
        displayFormat: "%0%",
        image: true,
        wrapperClass: "text-center",
        type: 'switch'
      },
      add: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        default: "1",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        display: ["crm"],
        displayFormat: "%0%",
        viewDisplay: "crm",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["crm"],
        displayFormat: "%0%",
        viewDisplay: "crm",
      },
    },

    {
      name: "description",
      label: "Deskripsi",
      filter: false,
      list: {
        allow: false,
        display: ["description"],
        viewDisplay: "description",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "textarea",
        maxLength: null,
        fileFormat: [],
        required: false,
        renderBefore: "",
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["description"],
        displayFormat: "%0%",
        viewDisplay: "description",
      },
    },

    {
      name: "active",
      label: "Status",
      filter: false,
      list: {
        allow: true,
        display: ["active"],
        viewDisplay: "active",
        displayFormat: "%0%",
        image: true,
        type: "badge",
        wrapperClass: "text-center",
        viewValues: [
          {
            label: "Aktif",
            value: 1,
            color: "success",
          },
          {
            label: "Tidak Aktif",
            value: 0,

            color: "secondary",
          },
        ],
      },
      add: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],

        default: "1",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "switch",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "1",
        options: [
          {
            label: "YA",
            value: "1",
          },
          {
            label: "Tidak",
            value: "0",
          },
        ],
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
      delete: { allow: false },
      view: {
        allow: true,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
      },
    },
  ],
};

export default model;
