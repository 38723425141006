import React, { Fragment } from "react";
import DataTable from "react-data-table-component";
import {
  Container,
  Row,
  Col,
  CardHeader,
  CardBody,
  Card,
  Button,
} from "reactstrap";
import Breadcrumb from "../../../layout/breadcrumb";

function GenericView({ state, onBack }) {
  return (
    <Fragment>
      <Breadcrumb parent="Kontak" title={state.model.label} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="d-flex flex-row justify-content-between">
                {/* <h5>{state.data.name}</h5> */}
                <h5>Detail {state.model.label}</h5>
                <Button color="light" onClick={() => onBack()}>
                  Kembali
                </Button>
              </CardHeader>
              <CardBody>
                {Object.keys(state.data).length > 0 ? (
                  <table className="generic-view">
                    {state.fields.map((field, i) => (
                      <tr key={i}>
                        <td>{field.label}</td>
                        <td>{field.value == null ? "-" : field.value}</td>
                      </tr>
                    ))}
                  </table>
                ) : (
                  <h6>Data tidak ditemukan</h6>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default GenericView;
