import React, { Component } from "react";
import { get } from "../../utils/API";
import MyProfile from "./MyProfile";

class MyProfileController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        email: "",
        name: "",
        phone: "",
        photo: "",
        role_id: null,
        role_name: "",
      },
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    get("me").then((response) => {
      this.setState({ data: response });
    });
  }

  render() {
    return <MyProfile state={this.state} />;
  }
}

export default MyProfileController;
