const model = {
  name: "agent",
  label: "Agent",
  addEndpoint: "agent",
  listEndpoint: "agent",
  editEndpoint: "agent/:id",
  viewEndpoint: "agent/:id",
  deleteEndpoint: "agent/:id",
  add: true,
  list: true,
  edit: true,
  view: true,
  delete: true,

  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: { allow: false },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "profile",
      label: "Profil",
      filter: false,
      list: {
        allow: true,
        class: ["text-center"],
        display: ["photo_thumb"],
        viewDisplay: "photo_thumb",
        wrapperClass: "text-center",
        displayFormat: "%0%",
        type: "image",
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
      add: { allow: false },
    },
    {
      name: "name",
      label: "Nama",
      filter: false,
      list: {
        allow: true,
        display: ["agent_name"],
        displayFormat: "%0%",
        viewDisplay: "agent_name",
        wrapperClass: "d-flex flex-column",
        class: ["mb-0 h6 font-weight-bold"],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["agent_name"],
        displayFormat: "%0%",
        viewDisplay: "agent_name",
      },

      view: {
        allow: true,
        display: ["agent_name"],
        displayFormat: "%0%",
        viewDisplay: "agent_name",
      },

      delete: {
        allow: true,
      },
    },

    {
      name: "email",
      label: "Email",
      filter: false,
      list: {
        allow: true,
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
        wrapperClass: "",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "email",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
      },

      view: {
        allow: true,
        display: ["email"],
        displayFormat: "%0%",
        viewDisplay: "email",
      },

      delete: {
        allow: true,
      },
    },

    {
      name: "phone",
      label: "No HP",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "phone",
        type: "phone",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: true,
        display: ["phone"],
        displayFormat: "%0%",
        viewDisplay: "phone",
      },
    },
    {
      name: "role_name",
      label: "Hak Akses",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: {
        allow: true,
        display: ["role_name"],
        displayFormat: "%0%",
        viewDisplay: "role_name",
      },
    },

    // {
    //   name: "email",
    //   label: "Email",
    //   filter: false,
    //   list: {
    //     allow: true,
    //     display: ["email"],
    //     displayFormat: "%0%",
    //     viewDisplay: "email",
    //     class: [],
    //     renderHtml: false,
    //     sortable: false,
    //     searchable: true,
    //   },
    //   add: {
    //     allow: false,
    //   },

    //   edit: {
    //     allow: true,
    //     validate: "email",
    //     type: "text",
    //     maxLength: null,
    //     fileFormat: [],
    //     required: true,
    //     renderBefore: "",

    //     display: ["email"],
    //     displayFormat: "%0%",
    //     viewDisplay: "email",
    //   },

    //   delete: {
    //     allow: true,
    //   },
    //   view: {
    //     allow: true,
    //     display: ["email"],
    //     displayFormat: "%0%",
    //     viewDisplay: "email",
    //   },
    // },
    {
      name: "device",
      label: "Device",
      filter: false,
      list: {
        allow: true,
        display: ["whatsapp_name"],
        displayFormat: "%0%",
        viewDisplay: "whatsapp_name",
        class: ["btn btn-primary"],
        wrapperClass: "text-center",
        type: "button",
        props: {
          size: "sm",
          color: "primary",
        },
        pathname: "blast/chat/list/:id/admin",
        // viewValues: [
        //   {
        //     label: "Aktif",
        //     value: 1,
        //     class: "badge badge-success",
        //   },
        //   {
        //     label: "Tidak Aktif",
        //     value: 0,

        //     class: "badge badge-secondary",
        //   },
        // ],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
        display: ["whatsapp_name"],
        displayFormat: "%0%",
        viewDisplay: "whatsapp_name",
        validate: null,
        required: true,
        viewValues: [
          {
            label: "Aktif",
            value: 1,
          },
          {
            label: "Tidak Aktif",
            value: 0,
          },
        ],
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["active"],
        type: "select",
        viewDisplay: "active",
        displayFormat: "%0%",
      },
    },
    {
      name: "active",
      label: "Status",
      filter: false,
      list: {
        allow: false,
        display: ["active", "active"],
        displayFormat: "%0%",
        viewDisplay: "active",
        class: ["badge badge-success", "badge badge-success"],
        viewValues: [
          {
            label: "Aktif",
            value: 1,
            class: "badge badge-success",
          },
          {
            label: "Tidak Aktif",
            value: 0,

            class: "badge badge-secondary",
          },
        ],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: true,
        display: ["active"],
        displayFormat: "%0%",
        viewDisplay: "active",
        validate: null,
        required: true,
        type: "select",
        viewValues: [
          {
            label: "Aktif",
            value: 1,
          },
          {
            label: "Tidak Aktif",
            value: 0,
          },
        ],

        options: [
          {
            label: "Aktif",
            value: true,
          },
          {
            label: "Tidak Aktif",
            value: false,
          },
        ],
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["active"],
        type: "select",
        viewDisplay: "active",
        displayFormat: "%0%",
      },
    },

    {
      name: "whatsapp",
      label: "Whatsapp",
      filter: false,
      list: {
        allow: false,
        display: ["whatsapp_name", "whatsapp_phone"],
        displayFormat: "%0% %1%",
        viewDisplay: "whatsapp",
        class: ["d-block", "d-block"],
        renderHtml: false,
        sortable: false,
        searchable: false,
      },
      add: {
        allow: false,
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["username"],
        displayFormat: "%0%",
        viewDisplay: "username",
      },
    },
    {
      name: "whatsapp_id",
      label: "Whatsapp",
      filter: false,
      list: {
        allow: false,
        display: ["name", "phone"],
        displayFormat: "%0%",
        viewDisplay: "whatsapp_phone",
        class: [],
        renderHtml: false,
        sortable: false,
        searchable: true,
      },
      add: {
        allow: true,
        validate: "integer",
        type: "lookup",
        display: ["name", "phone"],
        displayFormat: "%0% (%1%)",
        lookupEndpoint: "whatsapp",
        maxLength: null,
        required: true,
        renderBefore: "",
      },

      edit: {
        allow: false,
        validate: "phone",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["name"],
        displayFormat: "%0%",
        viewDisplay: "whatsapp",
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["username"],
        displayFormat: "%0%",
        viewDisplay: "username",
      },
    },
    {
      name: "password",
      label: "Password",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "string",
        type: "password",
        maxLength: null,
        minLength: 8,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["username"],
        displayFormat: "%0%",
        viewDisplay: "username",
      },
    },

    {
      name: "retype_password",
      label: "Konfirmasi Password",
      filter: false,
      list: {
        allow: false,
      },
      add: {
        allow: true,
        validate: "string",
        type: "password",
        maxLength: null,
        minLength: 8,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },

      edit: {
        allow: false,
      },

      delete: {
        allow: true,
      },
      view: {
        allow: false,
        display: ["password"],
        displayFormat: "%0%",
        viewDisplay: "password",
      },
    },
  ],
};

export default model;
