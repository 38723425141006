import classNames from "classnames";
import React, { Fragment, memo, useState } from "react";
import { useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Table,
  Button,
  Media,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import FormPagination from "../../components/forms/FormPagination";
import Breadcrumb from "../../layout/breadcrumb";
import { formatPhone } from "../../utils/Formatter";

function ContactList({
  state,
  onAdd,
  onView,
  onEdit,
  onDelete,
  onCustomAction,
  toggleActive,
  onChangePage,
  tableRef,
}) {
  const show = state.active.id ? true : false;
  return (
    <div ref={tableRef}>
      <Breadcrumb parent="Data" title="Kontak" />
      <Container fluid={true} className="contact-list-page">
        <Row className="wrappers">
          <Col sm="12">
            <Card className="wrapper">
              <div
                className={classNames("list-wrapper", {
                  half: show,
                })}
              >
                <CardHeader className="d-flex flex-row justify-content-between pb-4 pt-4">
                  <h5>Daftar Kontak</h5>

                  <Button size="md" color="primary" onClick={() => onAdd()}>
                    Tambah Kontak
                  </Button>
                </CardHeader>

                <CardBody className="m-0 p-0">
                  <div className="table-responsive">
                    <Table className="contact-table">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Profil</th>
                          <th>Nama</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.data.map((data, i) => (
                          <tr
                            onClick={() => toggleActive(data)}
                            className={classNames({
                              active: state.active.id == data.id,
                            })}
                          >
                            <td>{i + 1}</td>
                            <td className="align-middle">
                              <div
                                className={`avatar d-flex flex-row justify-content-center`}
                              >
                                <img
                                  src={
                                    data.photo
                                      ? data.photo
                                      : require("../../assets/images/logo/default-avatar.png")
                                  }
                                  className="img-60 rounded-circle"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = require("../../assets/images/logo/default-avatar.png");
                                  }}
                                />
                              </div>
                            </td>
                            <td className="align-middle">
                              <p className="m-0 h6 ">
                                {formatPhone(data.name)}
                              </p>
                              <p className="m-0 text-muted">
                                {formatPhone(data.phone)}
                              </p>
                            </td>
                            <td
                              className="align-middle"
                              style={{ minWidth: "10%" }}
                            >
                              <div className="action-button d-flex flex-row justify-content-end">
                                <Button
                                  color="light"
                                  size="xs"
                                  onClick={() => onDelete(data)}
                                >
                                  <i className="ri-delete-bin-fill text-danger h5 mb-0 pb-0"></i>
                                </Button>

                                <Button
                                  color="light"
                                  size="xs"
                                  onClick={() => onEdit(data)}
                                >
                                  <i className="ri-edit-2-fill text-primary h5 mb-0 pb-0"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <FormPagination
                    totalRecord={state.totalRecord}
                    limit={state.filter.limit}
                    offset={state.filter.offset}
                  />
                </CardBody>
              </div>
              <div
                className={classNames("detail-wrapper", {
                  show: show,
                })}
              >
                <div className="d-flex flex-row justify-content-between mb-4">
                  <p className=" mb-2 font-weight-bold">Detail Kontak</p>
                  <Button
                    close
                    color="primary"
                    size="lg"
                    onClick={() => toggleActive()}
                  />
                </div>

                <div className="d-flex flex-row justify-content-between">
                  <div>
                    <h6 className="font-weight-bold">
                      {state.active.whatsapp_name}
                    </h6>
                    <p className="m-0">{state.active.whatsapp_phone}</p>
                  </div>
                  <div
                    className={`avatar d-flex flex-row justify-content-center`}
                  >
                    <img
                      src={state.active.photo}
                      className="img-60 rounded-circle"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = require("../../assets/images/logo/default-avatar.png");
                      }}
                    />
                  </div>
                </div>

                <hr />

                {[
                  "Nama:whatsapp_name",
                  "Nomor Whatsapp:whatsapp_phone",
                  "Email:email",
                  "Address:address",
                ].map((detail, i) => (
                  <Row>
                    <Col md={4}>
                      <p>{detail.split(":")[0]}</p>
                    </Col>

                    <Col md={8}>
                      <p>: {state.active[detail.split(":")[1]]}</p>
                    </Col>
                  </Row>
                ))}
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default memo(ContactList);
