const model = {
  name: "tasks",
  label: "Tasks",
  addEndpoint: "tasks",
  listEndpoint: "tasks",
  editEndpoint: "tasks",
  viewEndpoint: "tasks/:id",
  deleteEndpoint: "tasks/:id",
  add: true,
  edit: true,
  list: true,
  delete: true,
  fields: [
    {
      name: "id",
      label: "ID",
      filter: false,
      list: { allow: false, display: ["id"], displayFormat: "%0%" },
      add: {
        allow: false,
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "task_name",
      label: "Name",
      filter: false,
      list: {
        allow: true,
        display: ["task_name"],
        viewDisplay: "task_name",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
      },
      edit: {
        allow: true,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["task_name"],
        displayFormat: "%0%",
        viewDisplay: "task_name",
      },
      delete: { allow: false },
      view: { allow: false },
    },

    {
      name: "task_group",
      label: "Group",
      filter: false,
      list: {
        allow: true,
        display: ["task_group"],
        viewDisplay: "task_group",
        displayFormat: "%0%",
        image: true,
      },
      add: {
        allow: false,
        validate: "string",
        type: "text",
        maxLength: null,
        fileFormat: [],
        required: true,
        renderBefore: "",
        default: "",
        display: ["task_group"],
        displayFormat: "%0%",
        viewDisplay: "task_group",
      },
      edit: { allow: false },
      delete: { allow: false },
      view: { allow: false },
    },
  ],
};

export default model;
